import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { LoggerService } from '../../services/logger.service';
import { RefactorFieldsService } from '../../services/refactor-fields.service';

@Component({
  selector: 'app-admin-data-table',
  templateUrl: './admin-data-table.component.html',
  styleUrls: ['./admin-data-table.component.css'],
})
export class AdminDataTableComponent implements OnInit {
  constructor(
    private refactorFieldsService: RefactorFieldsService,
    private workflowService: WorkflowService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorHandling: ErrorHandlingService,
    private logger: LoggerService
  ) {}

  @ViewChild('datatable') myDatatable: any;
  dataTable: any;

  dtOptions: DataTables.Settings = {};

  @Input() dataHead: any;
  @Input() searchableHeader: boolean = false;
  @Input() heightValue: any;
  @Input() outerArr: any;
  @Input() showingArr: any = [];
  @Input() allColumns: any = [];
  @Input() totalRows: any;
  @Input() firstPaginator: number = 0;
  @Input() lastPaginator: number = 0;
  @Input() currentPointer: number = 0;
  @Input() headerColName = '';
  @Input() direction = 0;
  @Input() paginatorAbsent = false;
  @Input() tabFilterProperty: any;
  @Input() cbModel: any;
  @Input() checkBox: any;
  @Input() columnWhiteList: any;
  @Input() buttonsArr: any;
  @Input() searchTextValues = '';
  @Input() popRows: any;
  @Input() parentName: any;
  @Input() buttonColumn: any;
  @Input() errorValue: any;
  @Input() showGenericMessage: any;
  @Input() tableIdAppend: any;
  @Input() searchPassed = '';
  @Input() checkedList: any = [];
  @Output() selectedRow = new EventEmitter<any>();
  @Output() menuClick = new EventEmitter<any>();
  @Output() searchRowTxt = new EventEmitter<any>();
  @Output() noScrollDetected = new EventEmitter<any>();
  @Output() searchTriggerred = new EventEmitter<any>();
  @Output() previousPageCalled = new EventEmitter<any>();
  @Output() tabSelected = new EventEmitter<any>();
  @Output() nextPageCalled = new EventEmitter<any>();
  @Output() cbClicked = new EventEmitter<any>();
  @Output() rowClickText = new EventEmitter<string>();
  @Output() emitPaginator = new EventEmitter<any>();

  @Input() regulatoryData: any;
  @Input() id: any;
  @Input() tableTitle: any;
  @Input() targetType: any;
  @Input() ruleFlag: any;
  @Input() assetGroupTableHeader: any;

  @Input() pageNumber:any;
  @Input() totalPages:any;


  showRegulatoryCards: boolean = true;
  regulatoryCompliance: any;

  errorMessage = 'apiResponseError';
  indexSelected: number = 0;
  sortArr: any = [];
  rowDetails: any = [];
  rowIndex = -1;
  noMinHeight = false;
  rowObj: any = {};
  searchVal = '';
  loaded = false;
  sortSelectedColomn = '';

  seekdata = false;
  showError = false;
  scrollEnabled = false;
  rowAccessProperty = 'text';
  private allTableData: any;
  tabsData: any;
  public filteredColumns: any = [];
  public animationState: any;
  public previousTableData: any = [];
  public currentTableData: any = [];
  currentRowData: any;

  ngOnInit() {
    this.dtOptions = {
      scrollCollapse: true,
      paging: false,
      scrollX: true,
      searching: false,
      info: false,
      ordering: false,
    };
    this.noMinHeight = false;
    this.seekdata = false;
    if (this.outerArr !== undefined && this.outerArr.length !== 0) {
      this.currentTableData = this.outerArr;
      this.allTableData = this.outerArr;
      if (this.tabFilterProperty) {
        this.tabsData = _(this.allTableData)
          .map((row: any) => {
            return row[this.tabFilterProperty];
          })
          .filter((row) => {
            return !!row[this.rowAccessProperty];
          })
          .uniqBy((row) => {
            return row[this.rowAccessProperty];
          })
          .value();

        for (let index = 0; index < this.tabsData.length; index++) {
          this.tabsData[index]['displayName'] =
            this.refactorFieldsService.getDisplayNameForAKey(
              this.tabsData[index]['text'].toLowerCase()
            ) || this.tabsData[index]['text'];
        }
      }
      this.restrictShownColumns(this.columnWhiteList);
      for (
        let column_index = 0;
        column_index < this.allColumns.length;
        column_index++
      ) {
        this.sortArr[column_index] = {
          showUp: undefined,
          direction: 0,
        };
      }
      this.loaded = true;
    } else {
      this.seekdata = true;
      this.showError = true;

      if (this.showGenericMessage === true) {
        this.errorMessage = 'apiResponseError';
      } else {
        if (this.searchTextValues === '') {
          this.errorMessage = this.parentName;
        } else {
          this.errorMessage = 'dataTableMessage';
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const graphDataChange = changes['allColumns'];
    const dataChange = changes['outerArr'];
    const errorChange = changes['showGenericMessage'];
    const errorMsg = changes['parentName'];
    const errorValueChange = changes['errorValue'];
    const searchTextChnage = changes['searchTextValues'];
    if (dataChange) {
      const cur = JSON.stringify(dataChange.currentValue);
      const prev = JSON.stringify(dataChange.previousValue);
      if (cur !== prev && this.allColumns) {
        this.ngOnInit();
      }
    }
    if (graphDataChange) {
      const cur = JSON.stringify(graphDataChange.currentValue);
      const prev = JSON.stringify(graphDataChange.previousValue);
      if (cur !== prev && this.allColumns) {
        this.ngOnInit();
      }
    }

    if (errorChange) {
      const cur = JSON.stringify(errorChange.currentValue);
      const prev = JSON.stringify(errorChange.previousValue);
      if (cur !== prev) {
        this.ngOnInit();
      }
    }

    if (searchTextChnage) {
      const cur = JSON.stringify(searchTextChnage.currentValue);
      const prev = JSON.stringify(searchTextChnage.previousValue);
      if (cur !== prev) {
        this.ngOnInit();
      }
    }

    if (errorMsg) {
      const cur: any = errorMsg.currentValue;
      this.errorMessage = cur;
    }

    if (errorValueChange) {
      if (
        errorValueChange &&
        (errorValueChange.currentValue === 0 ||
          this.errorValue.currentValue === -1)
      ) {
        this.currentTableData = [];
      }
    }
    this.isSeeMore();
  }

  goToCreateRules() {
    try {
      this.workflowService.addRouterSnapshotToLevel(
        this.router.routerState.snapshot.root
      );
      this.router.navigate(['../admin-create-rule'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {},
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  changeTabSelection(event: any) {
    const tab = event.tab;
    this.tabSelected.emit(tab);
    if (event.direction === 'right') {
      this.animationState = event.direction;
    }
    this.previousTableData = this.currentTableData;
    if (tab) {
      const list = _.filter(this.allTableData, (row) => {
        return (
          tab[this.rowAccessProperty] ===
          row[this.tabFilterProperty][this.rowAccessProperty]
        );
      });
      this.currentTableData = list;
      this.showRegulatoryCards = false;
    } else {
      this.currentTableData = this.allTableData;
      this.showRegulatoryCards = true;
    }
    this.scrollEnabled = true;
    setTimeout(() => {
      this.animationState = null;
    }, 400);
  }

  headerClicked(index: any, header: any) {
    this.rowIndex = -1;
    for (let i = 0; i < this.sortArr.length; i++) {
      if (i !== index) {
        this.sortArr[i].showUp = undefined;
        this.sortArr[i].direction = 0;
      } else {
        if (this.sortArr[i].direction === 0) {
          this.sortArr[i].direction = 1;
        } else {
          this.sortArr[i].direction = this.sortArr[i].direction * -1;
        }
        this.direction = this.sortArr[i].direction;
      }
    }
    this.indexSelected = index;
    this.headerColName = header;

    // for sort selected cloumn
    this.sortSelectedColomn = header;
  }

  complianceCardClicked(data: any) {
    if (data === 'hipaa') {
      this.currentTableData = this.outerArr;
      this.regulatoryCompliance = this.currentTableData.filter((tdata: any) => {
        return tdata.HIPAA.HIPAA;
      });
      this.currentTableData = this.regulatoryCompliance;
    } else if (data === 'gdpr') {
      this.currentTableData = this.outerArr;
      this.regulatoryCompliance = this.currentTableData.filter((tdata: any) => {
        return tdata.GDPR.GDPR;
      });
      this.currentTableData = this.regulatoryCompliance;
    } else if (data === 'soc2') {
      this.currentTableData = this.outerArr;
      this.regulatoryCompliance = this.currentTableData.filter((tdata: any) => {
        return tdata.SOC2.SOC2;
      });
      this.currentTableData = this.regulatoryCompliance;
    }
  }

  tableRowClicked(rowDetails: any, index: any) {
    this.rowDetails = [];
    this.rowObj = {};
    console.log(rowDetails)
    this.rowObj = rowDetails;
    this.rowDetails = Object.keys(this.rowObj);
    this.rowIndex = index;
  }

  getValueByKey(key: string): any {
   return 'Test Data';
    // return key;
  }

  goToDetails(thisRow: any, thisCol: any) {
    console.log('Trigger Event')
    console.log(thisRow)   
    console.log(thisCol)   
    if(thisCol === 'Rule Name') {
      this.router.navigateByUrl('/compliance/admin/admin-rules?ag=aws&domain=Infra%20%26%20Platforms');          
      return;
  }
  const details = {
      row: '',
      col: ''
  };
  details.row = thisRow;
  details.col = !!thisCol.value ? thisCol.value : thisCol;
  this.selectedRow.emit(details);
  }

  goToUpdate(thisRow: any, thisCol: any) {
    const details = {
      row: '',
      col: '',
    };
    details.row = thisRow;
    details.col = !!thisCol.value ? thisCol.value : thisCol;
    this.selectedRow.emit(details);
  }

  searchCalled(searchQuery: any) {
    this.searchRowTxt.emit(searchQuery);
    this.searchVal = searchQuery;
  }

  callCheckbox(i: any, row: any) {
    const data: any = {};
    data['index'] = i;
    data['data'] = row;
    this.cbClicked.emit(data);
  }

  triggerSearch() {
    this.noMinHeight = false;
    this.searchTriggerred.emit();
  }

  prevPage() {
    if (this.currentPointer > 0) {
      if(this.pageNumber>0){
        this.previousPageCalled.emit();
      }
    }
  }

  nextPage() {
    if (this.pageNumber+1 < this.totalPages) {
      this.nextPageCalled.emit();
      this.disbaleNextPage(); 
    }
  }

  //disable btn
  disbaleNextPage() {
    const div = document.querySelector('div.next-paginator');

    if (div) {
      if (this.lastPaginator === this.totalRows) {
        div.addEventListener('click', (event) => {
          event.preventDefault();
        });
      }
    } else {
      console.error('div not found in the DOM');
    }
  }

  isSeeMore() {
    try {
      setTimeout(() => {
        const a = document.getElementsByClassName(
          'data-table-inner-content data-table-current'
        );
        const b = document.getElementsByClassName(
          'data-table-inner-wrap relative'
        );

        if (a[0] && b[0]) {
          if (a[0].clientHeight && b[0].clientHeight) {
            if (a[0].clientHeight > b[0].clientHeight) {
              this.scrollEnabled = false;
            } else {
              this.scrollEnabled = true;
              if (this.currentTableData.length) {
                this.noMinHeight = true;
                this.noScrollDetected.emit(this.noMinHeight);
              }
            }
          }
        }
      }, 20);
    } catch (e) {
      this.logger.log('error', 'js error - ' + e);
    }
  }

  restrictShownColumns(columnNames: any) {
    if (columnNames) {
      const list = _.filter(columnNames, (whiteListedColumn) => {
        return _.find(this.allColumns, (column) => {
          return whiteListedColumn.toLowerCase() === column.toLowerCase();
        });
      });
      this.filteredColumns = list;
    } else {
      this.filteredColumns = this.allColumns;
    }
  }
  emitRowText(rowClickText: any) {
    this.rowClickText.emit(rowClickText);
  }

  menuClicked(row: any, menu: any) {
    const obj: any = {};
    obj['data'] = row;
    obj['type'] = menu;
    this.menuClick.emit(obj);
    row.dropDownShown = false;
  }

  goToCreateJobExecutionManager() {
    try {
      this.workflowService.addRouterSnapshotToLevel(
        this.router.routerState.snapshot.root
      );
      this.router.navigate(['../admin-create-job-execution-manager'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {},
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToCreateUpdateDomain() {
    try {
      this.workflowService.addRouterSnapshotToLevel(
        this.router.routerState.snapshot.root
      );
      this.router.navigate(['../admin-create-update-target-type'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {},
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToCreateAssetGroup() {
    try {
      this.workflowService.addRouterSnapshotToLevel(
        this.router.routerState.snapshot.root
      );
      this.router.navigate(['../admin-create-asset-groups'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {},
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
}

