
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { AssetGroupObservableService } from '../../../core/services/asset-group-observable.service';
import { TaggingComplianceService } from '../../services/tagging-compliance.service';
import { AutorefreshService } from '../../services/autorefresh.service';
import { Subscription } from 'rxjs/Subscription';
import { LoggerService } from '../../../shared/services/logger.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';
import { WorkflowService } from '../../../core/services/workflow.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-total-tag-compliance',
  templateUrl: './total-tag-compliance.component.html',
  styleUrls: ['./total-tag-compliance.component.css'],
  providers: [ LoggerService, ErrorHandlingService, TaggingComplianceService, AutorefreshService ],
  encapsulation: ViewEncapsulation.None
})
export class TotalTagComplianceComponent implements OnInit, OnDestroy {

  private tagsComplianceUrl = environment.taggingCompliance.url;
  private tagsComplianceMethod = environment.taggingCompliance.method;
  public returnedSearch = '';

  subscriptionToAssetGroup: Subscription;
  subscriptionToTaggingCompliance: Subscription |any;
  complianceData: any = [];
  selectedAssetGroup: string ='';
  overallPercentage: any;
  errorMessage: any;
  loaded = false;
  forData: any = [];
  durationParams: any;
  autoRefresh: boolean =false;
  contValue = false;
  seekdata = false;
  sampleData: any = [];
  tableHeaderData: any = [];
  complianceTableData: any = [];
  complianceTableHeaderData: any = [];
  donutId = 'taggingCompliance';
  public showLoader = false;
  targetType:any;
  showloader = false;
  private autorefreshInterval :any;
  private urlToRedirect: string='';
  @Input() pageLevel: number =0;

  constructor(private taggingComplianceService: TaggingComplianceService,
    private autorefreshService: AutorefreshService,
    private assetGroupObservableService: AssetGroupObservableService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private utils: UtilsService,
    private workflowService: WorkflowService ) {

      this.subscriptionToAssetGroup = this.assetGroupObservableService.getAssetGroup().subscribe(
      assetGroupName => {
          this.selectedAssetGroup = assetGroupName;
          this.updateComponent();
      });
      this.tableHeaderData = ['Untagged', 'Tagged'];
  }

  ngOnInit() {
    /* Variables to be set only first time when component is loaded should go here. */
    try {
      this.urlToRedirect = this.router.routerState.snapshot.url;
      this.durationParams = this.autorefreshService.getDuration();
      this.durationParams = parseInt(this.durationParams, 10);
      this.autoRefresh = this.autorefreshService.autoRefresh;
      const afterLoad = this;
      this.targetType='';
      if (this.autoRefresh !== undefined) {
        if ((this.autoRefresh === true ) || (this.autoRefresh.toString() === 'true')) {

          this.autorefreshInterval = setInterval(function(){
            afterLoad.getTaggingCompliance();
          }, this.durationParams);
        }
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }
  }

  /* Function to get Data */
  getData() {
    /* All functions to get data should go here */
    this.getTaggingCompliance();
  }

  updateTargetType(targettype:any){
    this.targetType=targettype;
    this.getData();
  }

  updateComponent() {
    this.contValue = false;
    this.loaded = false;
    /* All functions variables which are required to be set for component to be reloaded should go here */
    this.getData();
  }



  getTaggingCompliance(): void {
    try {
      this.showloader = true;
      if (this.subscriptionToTaggingCompliance) {
          this.subscriptionToTaggingCompliance.unsubscribe();
        }
        
        const queryParams: { [key: string]: string | number } = {
          'ag': this.selectedAssetGroup,
          'tenantId': 'UST_10001',
        };
    
        if (this.targetType && this.targetType !== "All Assets") {
          queryParams['targettype'] = this.targetType;
        }
      this.seekdata = false;
      this.showloader = true;
      this.subscriptionToTaggingCompliance = this.taggingComplianceService.getTaggingCompliance(queryParams, this.tagsComplianceUrl, this.tagsComplianceMethod).subscribe(
        response => {

          try {
            this.complianceData = response[0].data;
            this.complianceTableData = response[0].taggingStatus.data;
            this.complianceTableHeaderData = response[0].taggingStatus.header;
            this.contValue = true;
            this.showloader = true;
            this.loaded = true;
            if (this.complianceData.length === 0) {
              if (document.getElementById(this.donutId) != null) {
                  // @ts-ignore
                document.getElementById(this.donutId).innerHTML = '';
              }
              this.seekdata = true;
              this.showloader = true;
              this.errorMessage = 'noDataAvailable';

            } else {
                this.seekdata = false;
                this.showloader = false;
                this.overallPercentage = response[0].percent;
            }

          } catch (e) {
              this.contValue = true;
              this.showloader = true;
              this.seekdata = true;
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
          }

        }, error => {
          this.contValue = true;
          this.seekdata = true;
          this.showloader = true;
          if (error === 'apiResponseError') {
            this.errorMessage = error;
          }
        });
    } catch (e) {
      this.contValue = true;
      this.seekdata = true;
      this.showloader = true;
      this.errorMessage = this.errorHandling.handleJavascriptError(e);
    }
  }
  capitalizeFirstLetter(string :any): any {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

    /**
   * This function navigates the page mentioned in the routeTo variable with a querypareams
   */

  navigatePage(event:any) {
    try {
      const localObjKeys = Object.keys(event);
      const currentApp = event[localObjKeys[0]];
      const intanceType = event[localObjKeys[1]].toLowerCase();
      const apiTarget = {'TypeAsset' : 'taggable'};

      if ((intanceType !== undefined) && (currentApp !== undefined) ) {
        this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
        if (intanceType === 'tagged') {
          const eachParams = {'tagged': true, tagName: currentApp};
          let newParams = this.utils.makeFilterObj(eachParams);
          newParams = Object.assign(newParams, apiTarget);
          newParams['mandatory'] = 'tagged';
          this.router.navigate(['../../', 'assets' , 'asset-list'], { relativeTo: this.activatedRoute, queryParams: newParams, queryParamsHandling: 'merge'});
        } else if (intanceType === 'untagged') {
          const eachParams = {'tagged': false , tagName: currentApp};
          let newParams = this.utils.makeFilterObj(eachParams);
          newParams = Object.assign(newParams, apiTarget);
          newParams['mandatory'] = 'tagged';
          this.router.navigate(['../../', 'assets' , 'asset-list'], { relativeTo: this.activatedRoute, queryParams: newParams, queryParamsHandling: 'merge'});
        }
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
  /* navigatePage function ends here */

  ngOnDestroy() {
    try {
      this.subscriptionToAssetGroup.unsubscribe();
      this.subscriptionToTaggingCompliance.unsubscribe();
      clearInterval(this.autorefreshInterval);
    } catch (error) {
      this.logger.log('info', '--- Error while unsubscribing ---');
    }
  }

}
