import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { KeycloakService } from './keycloak.service';
import { environment } from 'src/environments/environment';
import { OnPremAuthenticationService } from './onprem-authentication.service';
import { HttpHeaders } from '@angular/common/http';
// import { AuthService, } from '@core';
// import { KeycloakService } from '@core/auth/keycloak.service';
// import { Constants } from '@shared/infrastructure';
// import { CommonService } from 'app/services/common.service';
// import { ResourceService } from 'app/services/resource-service';

/**
 * It performs the task at application startup
 */
@Injectable({
  providedIn: 'root',
})
export class Starter {
  constructor(
    private authService: AuthService,
    private keyCloakService: KeycloakService,
    private route: Router,
    private onpremService: OnPremAuthenticationService,
    // private commonservice: CommonService,
    // private resourceService: ResourceService
  ) {}

  public start() {
    console.log('keycloak test authentication')
  /**
   * code to get config in startup of application currently commented
   */
  //   this.resourceService.get(`${Constants.webApis.getLoginConfig}`).subscribe((resp: any) => {
  //     if (resp?.data) {
  //         if(resp.data.paceEnabled){

  //         }else{
  //           this.authService.internalLoginAllowed(resp.data.internalLoginAllowed);
  //           this.authService.ssoEnabled(resp.data.ssoEnabled);
  //           this.authService.currentTenantSSODomains(resp.data.currentTenantSSODomains || [])
  //         }
          
          
  //     }
  //     this.loader.stopLoading();
  // }, error => {
  //     this.loader.stopLoading();
  //     this.notifications.showError(this.translate.transform('PUBLIC.Login.errorMessage'));
  // });
    // if (window.self !== window.top) {

        // debugger;
      // this.authService.setKeycloakLogin(true);
    //   this.authService.isIframeFLow(window.self !== window.top);
      return this.keyCloakService.login().then(
      (value) => {
        if (value) {
          let parsedData=value.tokenParsed
          console.log(value)
          const payload = {
            clientId: ''
          };
          this.authService
          .getCurrentUser(
            environment.keycloak.Keycloakapiurl + 'current-user',
            'GET',
            payload,
            {}
          )
          .toPromise()
          .then(
            (result) => {
                    let userid=result.id

                    value={
                      // "access_token": value.token,
                      // "refresh_token": value.token,
                      "access_token": value.token,
                      "refresh_token": value.refreshToken,
                      "userInfo": {
                          "firstName": result.name,
                          "lastName": '',
                          "userRoles": result.authorities,
                          "defaultAssetGroup": "aws",
                          "userName": result.name,
                          "userId": result.name,
                          "email": ''
                      },
                      "success": true,
                      "scope": "resource-access",
                      "token_type": "bearer",
                      "message": "Authentication Successfull",
                      "expires_in": 41554
                  };
                    // return this.authService.doKeycloakLogin(value);
                    if(!this.onpremService.isAuthenticated()){
                      return this.authService.doKeycloakLogin(value);
                      }
                    
            },
            (error) => {
              // this.loginPending = false;
              // this.throwLoginError();
            }
          );
        

        
          
          
          // return this.authService.doLogin();
        }
        }).catch(()=> {
          localStorage.clear();
          sessionStorage.clear();
        });
        // } else {
        //   this.authService.setKeycloakLogin(false);
        //   if(this.authService.isUserLoggedIn()){ 
        //     this.authService.populateCommonData();
        //   }else {
        //     localStorage.clear();
        //     sessionStorage.clear();
        //     this.route.navigate(['/login']);          
        //   }
        // }
        return true;
  }

}