
export const MAPPING: any = {
    '_resourceId': 'Resource ID',
    'globalcloudid.keyword':'Global Cloud ID',
    '_issueId': 'Issue ID',
    'accountid': 'Account ID',
    '_targetType': 'Asset Type',
    'targetType': 'Asset Type',
    'targettype': 'Asset Type',
    'eventstatus': 'Event Status',
    'resourcetype': 'Asset Type',
    '_resolution': 'Resolution',
    '_awsAccount': 'AWS Account',
    '_awsRegion': 'Region',
    '_ruleName': 'Rule Name',
    'accountname': 'Account Name',
    'region': 'Region',
    '_lastUpdated': 'Last Updated',
    '_policyId': 'Policy ID',
    'discoverydate': 'Discovery Date',
    'subnetid': 'Subnet ID',
    'keyname': 'Key Name',
    'architecture': 'Architecture',
    'availabilityzone': 'Availability Zone',
    'ebsoptimized': 'Ebsoptimized',
    'firstdiscoveredon': 'First discovered on',
    'hypervisor': 'Hypervisor',
    'imageid': 'Image Id',
    'instanceid': 'Instance ID',
    'instancetype': 'Instance Type',
    'launchtime': 'Launch Time',
    'monitoringstate': 'Monitoring State',
    'privateipaddress': 'Private',
    'publicipaddress': 'Public',
    'statecode': 'State Code',
    'statename': 'State Name',
    'sourcedestcheck': 'Source Destcheck',
    'rootdevicetype': 'Root devicetype',
    'tenancy': 'Tenancy',
    'virtualizationtype': 'Virtualization Type',
    'vpcid': 'Vpc Id',
    'createdDate': 'Created Date',
    'executionId': 'Execution Id',
    'issueStatus': 'Issue Status',
    'modifiedDate': 'Modified Date',
    'pac_ds': 'Pac ds',
    'policyId': 'Policy Id',
    'ruleCategory': 'Rule Category',
    'rulecategory': 'Rule Category',
    'ruleId': 'Rule Id',
    'ruleid': 'Rule Id',
    'severity': 'Severity',
    'threadsafe': 'Threadsafe',
    'ruleKey': 'Rule Key',
    'qualysApi': 'Qualys Api',
    'Status': 'Status',
    'security': 'Security',
    'costoptimization': 'Cost Optimization',
    'costoptimisation': 'Cost Optimisation',
    'governance': 'Governance',
    'Severity': 'Severity',
    'RuleCategory': 'Rule Category',
    'AccounId': 'Account ID',
    'AccountId': 'Account ID',
    'AccountName': 'Account Name',
    'CreatedOn': 'Created On',
    'createdon': 'Created On',
    'Environment': 'Environment',
    'IssueId': 'Issue ID',
    'issueid': 'Issue ID',
    'ModifiedOn': 'Modified On',
    'modifiedon': 'Modified On',
    'PolicyId': 'Policy ID',
    'policyName': 'Policy Name',
    'policyname': 'Policy Name',
    'Region': 'Region',
    'ResourceId': 'Resource ID',
    'RuleId': 'Rule ID',
    'description': 'Description',
    'compliance_percent': 'Compliance %',
    'lastScan': 'Last Scanned',
    'lastscan': 'Last Scanned',
    'tags.Application.keyword': 'Application',
    'tags.Environment.keyword': 'Environment',
    'severitylevel': 'Severity Level',
    'ruleId.keyword': 'Rule ID',
    'attachmentstatus': 'Attachment Status',
    'privatednsname': 'Private DNS Name',
    'macaddress': 'Mac Address',
    '_loaddate': 'Load Date',
    'ownerid': 'Owner ID',
    'attachmentattachtime': 'Attachment attach time',
    'attachmentdelontermination': 'Attachment delon termination',
    'networkinterfaceid': 'Network Interface ID',
    'attachmentid': 'Attachment ID',
    'attachmentdeviceindex': 'Attachment device index',
    'engineversion': 'Engine Version',
    'storagetype': 'Storage Type',
    'percentprogress': 'Percent Progress',
    'masterusername': 'Master User Name',
    'licensemodel': 'License Model',
    'iamdatabaseauthenticationenabled': 'IAM database authentication enabled',
    'allocatedstorage': 'Allocated Storage',
    'instancecreatetime': 'Instance create time',
    'dbsnapshotidentifier': 'db snapshot identifier',
    'optiongroupname': 'Option groupname',
    'snapshotcreatetime': 'Snapshot createtime',
    'snapshottype': 'Snapshot Type',
    'dbsnapshotarn': 'db snapshot arn',
    'dbinstanceidentifier': 'db instance identifier',
    'assetsaffected': 'Assets Affected',
    'vulntype': 'vulnerability Type',
    'expiringin': 'expiring In ',
    'validuntil': 'valid Until',
    'validfrom': 'valid From',
    'resourceid': 'Resource ID',
    'environmentuntagged': 'environment Untagged',
    'roleuntagged': 'role Untagged',
    'stackuntagged': 'stack Untagged',
    'serviceid': 'Service ID',
    'servicename': 'Service Name',
    'severity.keyword': 'severity',
    '_resourceid.keyword': 'Resource ID',
    'targetType.keyword': 'Asset Type',
    'ruleCategory.keyword': 'rule Category',
    'rootdevicename': 'Root Device Name',
    'totalMemory': 'Total Memory',
    'lastVulnScan': 'Last Vuln Scan',
    'totalCost': 'Total Cost',
    'lastWeekCost': 'Last Week Cost',
    'statereasoncode': 'State Reason Code',
    'viewercertificatedefaultcertificate': 'Viewer Certificate Default Certificate',
    'lastmodifiedtime': 'Last modified time',
    'domainname': 'Domain Name',
    'viewercertificateminprotocolversion': 'Viewer Certificate Minprotocol version',
    'ipv6enabled': 'Ip v6 enabled',
    'httpversion': 'Http version',
    'sourcedbsnapshotidentifier': 'Source db snapshot identifier',
    'endpointhostedzoneid': 'Endpoint hosted zone id',
    'latestrestorabletime': 'Latest restorable time',
    'preferredmaintenancewindow': 'Preferred maintenance window',
    'availabilityzones': 'Availability Zones',
    'cacertificateidentifier': 'CA certificate identifier',
    'endpointaddress': 'Endpoint address',
    'copytagstosnapshot': 'Copy tags to snapshot',
    'dbinstanceclass': 'DB instance class',
    'backupretentionperiod': 'Backup retention period',
    'monitoringinterval': 'Monitoring interval',
    'preferredbackupwindow': 'Preferred backup window',
    'endpointport': 'Endpoint port',
    'dbinstancestatus': 'DB instance status',
    'storageencrypted': 'Storage encrypted',
    'dbiresourceid': 'DB iresource id',
    'dbclusterarn': 'DB cluster arn',
    'autominorversionupgrade': 'Auto minor version upgrade',
    'publiclyaccessible': 'Publicly accessible',
    'dbinstanceport': 'DB instance port',
    '_docid': 'Doc ID',
    '_entity': 'Entity',
    'sriovnetsupport': 'Sriov net support',
    '_entitytype': 'Asset Type',
    '_id': 'ID',
    'statetransitionreason': 'State transition reason',
    '_resourceid': 'Resource ID',
    'statereasonmessage': 'State reason message',
    'roleIdentifyingString': 'Role Identifying String',
    'targetstoppedDuration': 'Target Stopped Duration',
    'costUrl': 'Cost Url',
    'clienttoken': 'Client token',
    'host_name': 'Host Name',
    'install_status': 'Install Status',
    'ip_address': 'IP Address',
    'kernel_release': 'Kernel Release',
    'tags.application': 'Application Name',
    'tags.environment': 'Environment Name',
    'u_business_service': 'Business Service',
    'u_patching_director': 'Patching Director',
    'u_kernel_release': 'U Kernel Release',
    'u_projection_week': 'U Projection Week',
    'u_updatedby': 'Updated By',
    'pac_override_kernel_release': 'Pac Override Kernel Release',
    'pac_override_u_last_patched': 'Pac Override U Last Patched',
    'inscope': 'In Scope',
    '_repositoryid': 'Repository ID',
    '_branchid': 'Branch ID',
    '_pullrequestid': 'Pull Request ID',
    'policyUrl': 'Policy Url',
    'policyVersion': 'Policy Version',
    'numberOfRules': 'No of Rules',
    'policyDesc': 'Policy Description',
    'ruleUUID': 'Rule UID',
    'ruleName': 'Rule Name',
    'assetGroup': 'Asset Group',
    'alexaKeyword': 'Alexa Keyword',
    'ruleParams': 'Rule Params',
    'ruleFrequency': 'Rule Frequency',
    'ruleExecutable': 'Rule Executable',
    'ruleRestUrl': 'Rule Rest Url',
    'ruleType': 'Rule Type',
    'url': 'URL',
    'ruleArn': 'Rule Arn',
    'status': 'Status',
    'userId': 'User Id',
    'email' : 'Email',
    'firstName' : 'First Name',
    'lastName' : 'Last Name',
    'userName' : 'User Name',
    'displayName': 'Display Name',
    'jobName': 'Job Name',
    'jobType': 'Job Type',
    'jobFrequency': 'Job Frequency',
    'jobExecutable': 'Job Executable',
    'groupName': 'Group Name',
    'targetTypes': 'Asset Types',
    'domainDesc': 'Domain Desc',
    'domainName': 'Domain Name',
    'config': 'Config',
    'targetTypesCount': 'Asset Types Count',
    'targetName': 'Target Name',
    'targetDesc': 'Target Desc',
    'targetConfig': 'Target Config',
    'domain': 'Domain',
    'category': 'Category',
    'endpoint': 'Endpoint',
    'expiryDate': 'Expiry Date',
    'exceptionReason': 'Exception Reason',
    'exceptionName': 'Exception Name',
    'roleName': 'Role Name',
    'roleId': 'Role Id',
    'createdby': 'Created By',
    'modifiedby': 'Modified By',
    'createdBy': 'Created By',
    'createddate': 'Created Date',
    'modifieddate': 'Modified Date',
    'users': 'Users',
    'userRoleId': 'User Role Id',
    'roles': 'Roles',
    'strategytype': 'Strategy Type',
    '_projectid': 'Project ID',
    'displayid': 'Display ID',
    'pluginname': 'Plugin Name',
    'pluginid': 'Plugin ID',
    'accountdesc': 'Account Description',
    'starttime': 'Start Time',
    'endtime': 'End Time',
    'affectedresources': 'Affected Resources',
    'eventcategory': 'Event Category',
    'certtype': 'Type',
    'certstatus': 'Status',
    'validto': 'valid To',
    'application': 'Bcbsa:Application Name',
    'environment': 'Bcbsa:Environment Type',
    'appcontact': 'Contact',
    'updateddate': 'Updated Date',
    'updatedby': 'Updated By',
    'createtimestamp': 'Created Date',
    'exemptionenddate': 'Exemption End Date',
    'exemptionreason': 'Exemption Reason',
    'exemptionrequestid': 'Exemption Request ID',
    'exemptionstartdate': 'Exemption Start Date',
    'issueidlist': 'Issue ID List',
    'requeststatus': 'Request Status',
    'commentforlastaction': 'Admin Remarks',
    'resourceidlist': 'Resource ID List',
    'systemcomment': 'System Remarks',
    'metadata': 'Additional Info',
    'uniqueAutoFixEnabled': 'Auto Fix Enabled',
    'autoFixEnabled': 'Auto Fix Enabled',
    'tags.name': 'Name',
    'tags.role': 'Role',
    'tags.owner': 'Owner',
    'tags.stack': 'Stack',
    'tags.channel': 'Channel',
    'tags.tier': 'Tier',
    'tags.workload': 'Workload',
    'reactorid': 'Reactor Id',
    'reactorname': 'Reactor Name',
    'eventsofinterest': 'Events Of Interest',
    'enforcedaccounts': 'Enforced Accounts',
    'cost_optimizing': 'Cost Optimization',
    'service_limits': 'Service Limits',
    'fault_tolerance': 'Fault Tolerance',
    'checkName': 'Recommendations',
    'monthlysavings': 'potential monthly savings',
    'recommendationid': 'Recommendation ID',
    'loadbalancername': 'Load Balancer Name',
    'volumeid': 'Volume ID',
    'agentInstalled': 'Agent Installed',
    'passwordresetrequired': 'Password Reset Required',
    'createdate': 'Creation Date',
    'userid': 'User Id',
    'passwordcreationdate': 'Password Creation Date',
    'mfaenabled': 'MFA Enabled',
    'arn': 'ARN',
    'passwordlastused': 'Password Last Used',
    'groupid': 'Group Id',
    'groupname': 'Group Name',
    'tags.kubernetescluster': 'Kubernetes Cluster ',
    'rotationstatus': 'Rotation Status',
    'deletiondate': 'Deletion Date',
    'awsaccountid': 'AWS Account Id',
    'keyid': 'Key Id',
    'keystate': 'Key State',
    'keyenabled': 'Key Enabled',
    'aliasname': 'Alias Name',
    'keyusage': 'Key Usage',
    'aliasarn': 'Alias ARN',
    'cidrblockstatusmessage': 'Cidr Block Status Message',
    'dhcpoptionsid': 'Dhcp Options Id',
    'cidrblockset': 'Cidr Blockset',
    'cidrblock': 'Cidr Block',
    'isdefault': 'Is Default',
    'cidrblockassociationid': 'Cidr Block Association Id',
    'cidrblockstate': 'Cidr Block State',
    'dataSourceName': 'Data Source',
    'vpc': 'VPC',
    'kms': 'KMS',
    'sg': 'SG',
    'riskreferenceid': 'Risk Reference Id',
    'riskdescription': 'Risk',
    'dateofrisk': 'Date Of Risk',
    'residualriskrating': 'Risk Residual Rating',
    'riskcategory':'Severity',
    'affectedfunctions':'Business Function',
    'department': 'Department',
    'service': 'Service',
    'riskprobability': 'Risk Probability',
    'riskimpact': 'Risk Impact',
    'riskvalue': 'Risk Value',
    'risktreatment': 'Risk Treatment',
    'mitigationplan': 'Mitigation Plan',
    'planmitigationdate': 'Plan Mitigation Date',
    'riskowner': 'Risk Owner',
    'riskmitigationstatus': 'Risk Mitigation Status',
    'residualrisklikelihood': 'Risk Risidual Likelihood',
    'residualimpact':'Residual Impact',
    'riskid': 'Risk Id',
    '_cloudtype': 'Cloud Type',
    'globalcloudid':'Global Cloud ID',
    'jobId':'Job ID',
    'checkcategory':'Check Category',
    'tenantid':'Tenant ID',
    'checkdescription':'Check Description',
    'checkid':'Check ID',
    'checkname':'Check Name',
    'associationipownerid':'Association Power ID',
    'dhcpconfigurations':'Dhcp Configurations',
    'associationpubip':'Association Pub IP',
    'associationpubdnsname':'Association Pubdns Name',
    'nondisplayableattributes': 'Non Displayable Attributes',
    'resourcettype': 'Resource Type',
    'missedatags': 'Missed Tags'
  };