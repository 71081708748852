import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonResponseService } from './services/common-response.service';
import { ErrorHandlingService } from './services/error-handling.service';
import { HttpService } from './services/http-response.service';
import { LoggerService } from './services/logger.service';
import { RefactorFieldsService } from './services/refactor-fields.service';
import { UtilsService } from './services/utils.service';
import { RouterUtilityService } from './services/router-utility.service';
import { AuthGuardService } from './services/auth-guard.service';
import { DataTableComponent } from './components/data-table/data-table.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { OverallComplianceService } from './services/overall-compliance.service';
import { TableTabsComponent } from './components/table-tabs/table-tabs.component';
import { TitleBurgerHeadComponent } from './components/title-burger-head/title-burger-head.component';
import { DataTablesModule } from 'angular-datatables';
import { AppCircleChartComponent } from './components/app-circle-chart/app-circle-chart.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PolicyViolationTableComponent } from './components/policy-violation-table/policy-violation-table.component';
import { GenericSummaryComponent } from './components/generic-summary/generic-summary.component';
import { CompliantCircleChartComponent } from './components/compliant-circle-chart/compliant-circle-chart.component';
import { ContentSliderComponent } from './components/content-slider/content-slider.component';
import { PolicyGenericSummaryComponent } from './components/policy-generic-summary/policy-generic-summary.component';
import { FilterInfoComponent } from './components/filter-info/filter-info.component';
import { FilterManagementService } from './services/filter-management.service';
import { TableListComponent } from './components/table-list/table-list.component';
import { GenericPageFilterComponent } from './components/generic-page-filter/generic-page-filter.component';
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FilteredSelectorComponent } from './components/filtered-selector/filtered-selector.component';
import { AdminDataTableComponent } from './components/admin-data-table/admin-data-table.component';
import { DomainTableComponent } from './components/domain-table/domain-table.component';
import { PolicyTableComponent } from './components/policy-table/policy-table.component';
import { AssetDataTableComponent } from './components/asset-data-table/asset-data-table.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { AssetGenericSummaryComponent } from './components/asset-generic-summary/asset-generic-summary.component';
import { RulesTableComponent } from './components/rules-table/rules-table.component';
import { RolesAllocationTableComponent } from './components/roles-allocation-table/roles-allocation-table.component';
import { SticyTableComponent } from './components/sticy-table/sticy-table.component';
// import { NgxDatesPickerModule } from 'ngx-dates-picker';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { FormService } from './services/form.service';
import { FormsComponent } from './components/forms/forms.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { ConfigHistoryDropdownComponent } from './components/config-history-dropdown/config-history-dropdown.component';
import { ButtonComponent } from './components/button/button.component';
import { LoaderMsgComponent } from './components/loader-msg/loader-msg.component';
import { InputModalComponent } from './components/input-modal/input-modal.component';
import { CircleChartGraphComponent } from './components/circle-chart-graph/circle-chart-graph.component';
import { CircleGraphComponent } from './components/circle-graph/circle-graph.component';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { GrcTableComponent } from './components/grc-table/grc-table.component';
import { GrcRiskTableComponent } from './components/grc-risk-table/grc-risk-table.component';
import { NotificationTableComponent } from './components/notification-table/notification-table.component';
import { RecommendationsTableComponent } from './components/recommendations-table/recommendations-table.component';
import { AssetGroupTableComponent } from './components/asset-group-table/asset-group-table.component';
import { TableRowSearchComponent } from './components/table-row-search/table-row-search.component';
import { PageLoaderComponent } from './components/pageloader/pageloader.component';
import { SearchDownloadComponent } from './components/search-download/search-download.component';

@NgModule({
    declarations: [
      DataTableComponent,
      PolicyTableComponent,
      DomainTableComponent,
      TableTabsComponent,
      TitleBurgerHeadComponent,
      AppCircleChartComponent,
      OrderByPipe,
      SearchFilterPipe,
      ErrorMessageComponent,
      PageNotFoundComponent,
      PageLoaderComponent,
      PolicyViolationTableComponent,
      GenericSummaryComponent,
      CompliantCircleChartComponent,
      ContentSliderComponent,
      PolicyGenericSummaryComponent,
      FilterInfoComponent,
      TableListComponent,
      GenericPageFilterComponent,
      SearchableDropdownComponent,
      FilteredSelectorComponent,
      AdminDataTableComponent,
      AssetDataTableComponent,
      DoughnutChartComponent,
      AssetGenericSummaryComponent,
      RulesTableComponent,
      RolesAllocationTableComponent,
      SticyTableComponent,
      GenericModalComponent,
      OverlayComponent,
      FormsComponent,
      ConfirmationBoxComponent,
      ConfigHistoryDropdownComponent,
      ButtonComponent,
      LoaderMsgComponent,
      InputModalComponent,
      CircleChartGraphComponent,
      CircleGraphComponent,
      ButtonIconComponent,
      BarChartComponent,
      GrcTableComponent,
      GrcRiskTableComponent,
      NotificationTableComponent,
      RecommendationsTableComponent,
      AssetGroupTableComponent,
      TableRowSearchComponent,
      SearchDownloadComponent
    ],
    imports: [
      CommonModule,
      NgApexchartsModule,
      DataTablesModule,
      FormsModule,
      SelectDropDownModule,
      ReactiveFormsModule,
      AngularMyDatePickerModule
      // NgxDatesPickerModule
    ],
    providers: [
      CommonResponseService,
      ErrorHandlingService,     
      HttpService,
      LoggerService,
      RefactorFieldsService,
      UtilsService,
      RouterUtilityService,
      AuthGuardService,
      OverallComplianceService,
      OrderByPipe,
      FilterManagementService,
      FormService
    ],

    exports: [
      DataTableComponent,
      DomainTableComponent,
      PolicyTableComponent,
      RulesTableComponent,
      RolesAllocationTableComponent,
      SticyTableComponent,
      FormsModule,
      ReactiveFormsModule,
      NgApexchartsModule,
      TitleBurgerHeadComponent,
      AppCircleChartComponent,
      OrderByPipe,
      SearchFilterPipe,
      ErrorMessageComponent,
      PageNotFoundComponent,
      PageLoaderComponent,
      PolicyViolationTableComponent,
      GenericSummaryComponent,
      CompliantCircleChartComponent,
      ContentSliderComponent,
      PolicyGenericSummaryComponent,
      FilterInfoComponent,
      TableListComponent,
      GenericPageFilterComponent,
      SearchableDropdownComponent,
      SelectDropDownModule,
      FilteredSelectorComponent,
      AdminDataTableComponent,
      AssetDataTableComponent,
      DoughnutChartComponent,
      AssetGenericSummaryComponent,
      GenericModalComponent,
      OverlayComponent,      
      FormsComponent,
      ConfirmationBoxComponent,
      ConfigHistoryDropdownComponent,
      ButtonComponent,
      LoaderMsgComponent,
      InputModalComponent,
      CircleChartGraphComponent,
      CircleGraphComponent,
      ButtonIconComponent,
      BarChartComponent,
      GrcTableComponent,
      GrcRiskTableComponent,
      NotificationTableComponent,
      RecommendationsTableComponent,
      AssetGroupTableComponent,
      TableRowSearchComponent,
      SearchDownloadComponent
    ]
  })
export class SharedModule {
}