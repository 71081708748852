import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-list-detail-summary',
  templateUrl: './asset-list-detail-summary.component.html',
  styleUrls: ['./asset-list-detail-summary.component.css']
})
export class AssetListDetailSummaryComponent implements OnInit {
  @Input() dataArray: any;
  dataValue: any;
  colors: any = ['#d40325', '#1c5066', '#000', '#000'];
  complianceColors = {
    'non-compliant' : '#D40325',
    'compliant'     : '#00B946',
    'intermediate'  : '#F75C03'
  };
  percent: any = [true, true, false, false];
  outOf: any= [false, false, false, false];
  text: any = [];
  data = {
    'overallCompliance': 100,
    'utilizationScore': 80,
    'running': 'Running',
    'instanceId': '34625-13',
    'runningGif': 'assets/icons/instanceState.gif'
  };

  constructor() { }

  ngOnInit() {
    console.log("dataArray Data: ", this.dataArray)
    this.processData(this.dataArray);
  }


  processData(data: any) {
    console.log("Process Data: ", data)
    const response = [];
    let overallCompliance;
    let utilizationScore;
    let running;
    let instanceId;

    for (let i = 0; i < this.dataArray.length; i++) {

      if (this.dataArray[i].name === 'resourceId') {
        instanceId = {
         'value': this.dataArray[i].value,
         'text': 'Resource Id',
         'styling': {
            'cursor': 'pointer'
          }
        };
        this.colors[i] = '#000';
        this.percent[i] = false;
        this.text[i] = true;

        response.push(instanceId);
      }

      if (this.dataArray[i].name === 'Overall Compliance') {
        overallCompliance = {
         'value': parseInt(this.dataArray[i].value, 10),
         'text': 'Overall Compliance',
         'styling': {
            'cursor': ''
          }
        };
        this.colors[i] = this.dataArray[i].value === 100 ? this.complianceColors.compliant : (this.dataArray[i].value >= 50 ? this.complianceColors.intermediate : this.complianceColors['non-compliant']);
        this.percent[i] = true;
        this.text[i] = false;

        response.push(overallCompliance);
      }

      if (this.dataArray[i].name === 'Utilization Score') {
        utilizationScore = {
         'value': this.dataArray[i].value,
         'text': 'Utilization Score',
         'styling': {
            'cursor': ''
          }
        };
        this.percent[i] = false;
        this.outOf[i] = true;
        this.text[i] = false;

        response.push(utilizationScore);
      }

      if (this.dataArray[i].name === 'statename') {
        if (this.dataArray[i].value === 'running') {
          running = {
           'value': this.dataArray[i].value,
           'text': 'Instance State',
           'image': 'assets/icons/instanceState.gif',
           'styling': {
              'cursor': 'pointer'
            }
          };
        } else {
          running = {
           'value': this.dataArray[i].value,
           'text': 'Instance State',
           'image': 'assets/icons/Terminated.svg',
           'styling': {
              'cursor': 'pointer'
            }
          };
        }

        this.colors[i] = '#000';
        this.percent[i] = false;
        this.text[i] = true;

        response.push(running);
      }

    }

    this.dataValue = {
      'response': response
    };

  }

}
