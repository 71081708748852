import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { DataCacheService } from '../../core/services/data-cache.service';
import { AssetGroupObservableService } from '../../core/services/asset-group-observable.service';
import { LoggerService } from '../../shared/services/logger.service';
import { AssetTilesService } from '../../core/services/asset-tiles.service';
import { DomainTypeObservableService } from '../../core/services/domain-type-observable.service';
import {ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {RouterUtilityService} from '../../shared/services/router-utility.service';
import {Subscription} from 'rxjs/Subscription';
import {WorkflowService} from '../../core/services/workflow.service';
import {environment} from "../../../environments/environment";
import { RecentlyViewedObservableService } from 'src/app/core/services/recently-viewed-observable.service';
import { UpdateRecentAGService } from '../common/services/update-recent-ag.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { TENANT_ID } from 'src/app/shared/constants/tenant';

@Component({
  selector: 'app-default-asset-group',
  templateUrl: './default-asset-group.component.html',
  styleUrls: ['./default-asset-group.component.css'],
  providers: [DataCacheService]
})

export class DefaultAssetGroupComponent implements OnInit, OnDestroy {

  @ViewChild('staticBackdrop', {static: false}) staticModal?: ElementRef;
  @Input() defaultAssetGroup: string ='';
  @Input() provider :any = [];
  @Input() dataLoaded :any;
  private assetGroupSubscription: Subscription |any;
  private domainSubscription: Subscription |any;
  private subscriptionToAssetGroup: Subscription |any;
  private assetDetailsSubscription: Subscription |any;
  private assetTilesSubscription: Subscription |any;
  private updateRecentAGSubscription: Subscription |any;
  recentTiles:any = [];

  public assetGroupDisplayName = '';
  private assetGroupName :any;
  private assetGroupList :any;
  private domainName: string ='';
  assetTiles: any = [];
  assetTabName: any;
  selectedTabName: any;
  returnedSearch: any = '';
  thisAssetTile: any;
  selectedGroup: any;
  assetDetailsState = 0;
  assetDetailTiles: any;
  sendAssetName: any;
  loaded = false;
  clicked = false;

  constructor(private dataStore: DataCacheService,
    private assetGroupObservableService: AssetGroupObservableService,
    private logger: LoggerService,
    private assetTileService: AssetTilesService,
    private domainTypeObservableService: DomainTypeObservableService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routingUtilityService: RouterUtilityService,
    private workflowService: WorkflowService,
    private recentAssetsObservableService: RecentlyViewedObservableService,
    private routerUtilityService: RouterUtilityService,
    private updateRecentAGService: UpdateRecentAGService,
    private utils: UtilsService,
    private cdr:ChangeDetectorRef) {
      this.recentAssetsObservableService.getRecentAssets().subscribe(recentList => {
        this.recentTiles = recentList;
      });
      this.subscriptionToAssetGroup = this.assetGroupObservableService.getAssetGroup().subscribe(
        assetGroupName => {
            this.thisAssetTile = assetGroupName;
            this.selectedGroup = assetGroupName;
            this.assetTileClicked(this.thisAssetTile);
        });
     }

  ngOnInit() {
    try {
      this.subscribeToAssetGroupChange();
      this.subscribeToDomainChange();

    } catch (error) {
      this.logger.log('error', error);
    }
  }

  // ngAfterViewInit() {
  //   try {
  //     if (this.subscriptionToAssetGroup) {
  //       this.subscriptionToAssetGroup.unsubscribe();
  //     }
  //     this.loaded = false;
  //     this.retrieveFragment();
  //     this.getAssetTiles();
  //   } catch (error) {
  //     // this.errorHandlingService.handleJavascriptError(error);
  //     this.logger.log('error', error);
  //   }
  // }

  openModal() {
    if(this.staticModal != null){
      (this.staticModal?.nativeElement as HTMLElement).style.display = 'block';
      document.body.classList.add('modal-open');
      // document.querySelector('.modal-backdrop')?.classList.add('show');
      // (document.querySelector('.modal-backdrop') as HTMLElement).style.display = 'block';
    }
    this.loaded = false;
    this.retrieveFragment();
    this.getAssetTiles();
  }

  closeModal() {
    (this.staticModal?.nativeElement as HTMLElement).style.display = 'none';
    document.body.classList.remove('modal-open');
    // document.querySelector('.modal-backdrop')?.classList.remove('show');
    // (document.querySelector('.modal-backdrop') as HTMLElement).style.display = 'none';
  }
  
  

  openOverlay(): void {
    this.clicked = true;
  }

  closeOverlay() {
    this.clicked = false;
  }

  // this will detect the chage in platform selection 
  changeAg(agData :any) {
    console.log("agData", agData)
    const updatedFilters = JSON.parse(JSON.stringify(this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root)));
    updatedFilters['ag'] = agData.ag;   
    // this.router.navigate(['/pl/compliance/compliance-dashboard'], { 
    this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: updatedFilters
      });
}

  subscribeToAssetGroupChange() {

    this.assetGroupName = this.dataStore.getCurrentSelectedAssetGroup();
    if (this.assetGroupName) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'ag': this.assetGroupName
        },
        queryParamsHandling: 'merge'
      };
      this.router.navigate([], navigationExtras);
    }

    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assGroupName => {
      if (assGroupName) {
        this.assetGroupName = assGroupName;
        this.getAssetGroupDisplayName(this.assetGroupName);
      }
    });
  }

  subscribeToDomainChange() {
    this.domainSubscription = this.domainTypeObservableService.getDomainType().subscribe(domain => {
      this.domainName = domain;
    });
  }

  getAssetGroupDisplayName(assetGroupName :any) {

    /* To check if asset group list is available in cache */
    const assetGroupList = this.dataStore.getListOfAssetGroups();

    if (!assetGroupList) {

      /* Asset group list not available, getting the list */

      const assetUrl = environment.assetTiles.url;
      const assetMethod = environment.assetTiles.method;

      this.assetTileService.getAssetTiles(assetUrl, assetMethod).subscribe(
          response => {
            this.assetGroupList = response[0];
            if (this.assetGroupList) {
              /* Store the list in stringify format */
              this.dataStore.setListOfAssetGroups(JSON.stringify(this.assetGroupList));
              this.fetchAssetGroupDisplayName(assetGroupName);
            }
          },
          error => {
            this.logger.log('error', error);
          }
      );
    } else {
      /* If list is availbe then get asset group display name, domain list from a matchign asset group name */
      this.assetGroupList = JSON.parse(assetGroupList);
      this.fetchAssetGroupDisplayName(assetGroupName);
    }
  }

  fetchAssetGroupDisplayName(assetGroupName :any) {
    if (this.assetGroupList) {
      let allAssetGroups;
      if (typeof(this.assetGroupList) === 'string') {
        allAssetGroups = JSON.parse(this.assetGroupList);
      } else {
        allAssetGroups = this.assetGroupList;
      }

      let isAgPresent = false;

      const filteredArray = allAssetGroups.filter((element:any) => element.name === assetGroupName);

      if (filteredArray.length) {
        isAgPresent = true;
        const assetGroupObject = filteredArray[0];
        this.assetGroupDisplayName = assetGroupObject.displayname;
        this.getUpdatedDomain(assetGroupName, assetGroupObject);
      }

      if (!isAgPresent) {
        // this.router.navigate(['/pl', {outlets: { modal: ['change-default-asset-group'] } }]);
        console.log("URL: ", this.router.navigate(['/pl', {outlets: { modal: ['change-default-asset-group'] } }]))
      }
    }
  }

  getUpdatedDomain(assetGroupName :any, assetGroupObject :any) {

    if (assetGroupObject.domains && assetGroupObject.domains.length > 0 ) {
      this.domainTypeObservableService.updateListOfDomains(assetGroupObject.domains.join('~'));

      let newDomain = this.dataStore.getCurrentSelectedDomain(assetGroupName) ? this.dataStore.getCurrentSelectedDomain(assetGroupName) : assetGroupObject.domains[0];

      // TODO: Need to get confirmation
      if (newDomain !== null && newDomain !== "Infra & Platforms" ) {
        newDomain = "Infra & Platforms";
      }
      
      
      const deepestUrl = this.routingUtilityService.getJointDeepestPageUrl(this.activatedRoute.snapshot);

      const isActiveUrlACommonModulePage = this.routingUtilityService.checkIfCurrentRouteBelongsToCommonPages(deepestUrl);

      const currentModule = this.routingUtilityService.getModuleNameFromCurrentRoute(this.activatedRoute.snapshot);

      const landingPageUrl = this.routingUtilityService.getLandingPageInAModule(currentModule);

      const navigationExtras: NavigationExtras = {
        queryParams: {
          'domain': newDomain
        },
        queryParamsHandling: 'merge'
      };

      if (!isActiveUrlACommonModulePage && this.domainName && landingPageUrl && newDomain !== this.domainName) {
        /* Clears the saved url from module as different module may not have that page */
        this.workflowService.clearDataOfOpenedPageInModule();
        this.router.navigate([landingPageUrl], navigationExtras);
      } else {
        this.router.navigate([], navigationExtras);
      }
    } else {
      this.domainTypeObservableService.updateListOfDomains('');
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'domain': ''
        },
        queryParamsHandling: 'merge'
      };

      this.router.navigate([], navigationExtras);
    }
  }

retrieveFragment() {
  this.activatedRoute.fragment.subscribe((fragment: string) => {
    this.selectedTabName = fragment;
  });
}

getSearch(search: any) {
  this.returnedSearch = search;
}

getAssetTiles(): void {
  // this.showError = false;

  const assetGroupList = this.dataStore.getListOfAssetGroups();

  if (!assetGroupList || assetGroupList === 'undefined') {

      const assetUrl = environment.assetTiles.url;
      const assetMethod = environment.assetTiles.method;

      this.assetTilesSubscription = this.assetTileService.getAssetTiles(assetUrl, assetMethod).subscribe(
          response => {
              this.assetTiles = response[0];
              console.log("Tiles ", this.assetTiles)
              this.dataStore.setListOfAssetGroups(JSON.stringify(this.assetTiles));
              this.processData();
          },
          error => {
              this.loaded = true;
              // this.showError = true;
              this.logger.log('error', error);
          });
  } else {
      this.assetTiles = JSON.parse(assetGroupList);
      this.processData();
  }
}

getCurrentAssetInfo(assetInfo: any) {

}
assetTileClicked(tile: any) {
   this.thisAssetTile = tile;
   this.getAssetDetailTiles(tile);
   this.updateRecentAssetGroup(tile);
}

setDefault() {
  try {

    this.instructParentToCloseAssetGroup(this.thisAssetTile);
    const userDefaultAssetGroup = this.dataStore.getUserDefaultAssetGroup();

    if (this.thisAssetTile !== userDefaultAssetGroup) {
      this.updateDefaultAssetGroupForUser(this.thisAssetTile);
    }
  } catch (error) {
    // this.errorHandlingService.handleJavascriptError(error);
    this.logger.log('error', error);
  }
}

selectAsset(assetGroup: any) {
  try {
    this.instructParentToCloseAssetGroup(assetGroup.name);
    if (assetGroup.name !== this.selectedGroup) {
      this.selectedGroup = assetGroup.name;

      this.assetTileClicked(assetGroup.name);
    }
  } catch (error) {
    // this.errorHandlingService.handleJavascriptError(error);
    this.logger.log('error', error);
  }
}

updateDefaultAssetGroupForUser(assetGroup: any) {
  try {

    const updateAssetGroupUrl = environment.saveDefaultAssetGroup.url;
    const updateAssetGroupMethod = environment.saveDefaultAssetGroup.method;

    const userId = this.dataStore.getUserDetailsValue().getUserId();

    this.assetTilesSubscription = this.assetTileService.updateDefaultAssetGroupForUser(updateAssetGroupUrl, updateAssetGroupMethod, assetGroup, userId).subscribe(
      response => {
        this.dataStore.setUserDefaultAssetGroup(assetGroup);
      },
      error => {
     });
  } catch (error) {
    // this.errorHandlingService.handleJavascriptError(error);
  }
}

processData() {
  try {
    const typeObj: any = {
    'all': 'typeVal',
    'recently viewed': 'typeVal'
    };
    for ( let i = 0 ; i < this.assetTiles.length; i++) {
      typeObj[this.assetTiles[i].type.toLowerCase()] = 'typeVal';
    }
    delete typeObj[''];
    let typeArr = [];
    typeArr = Object.keys(typeObj);
    this.assetTabName = typeArr;
    /* Bottom line is not required */
    // this.selectedTabName = this.assetTabName[this.selectedTab];
    this.loaded = true;
  } catch (error) {
    // this.errorHandlingService.handleJavascriptError(error);
    this.logger.log('error', error);
  }
}

updateTab(newTab: any) {
  this.selectedTabName = newTab.toLowerCase();
}

getAssetDetailTiles(groupName: any) {

  const assetDetailUrl = environment.assetTilesdata.url;

  const assetDetailMethod = environment.assetTilesdata.method;

  const queryParams = {
    'ag': groupName,
    'tenantId': TENANT_ID.tenantId
  };

  this.assetDetailsState = 0;

   if (queryParams['ag'] !== undefined) {

   this.assetDetailsSubscription = this.assetTileService.getAssetdetailTiles(queryParams, assetDetailUrl, assetDetailMethod).subscribe(
     response => {
       this.assetDetailsState = 1;
       this.assetDetailTiles = response[0];
    },
    error => {
        this.assetDetailsState = -1;
    });
   }
}

updateRecentAssetGroup(groupName: any) {

  const updateRecentAGUrl = environment.updateRecentAG.url;

  const updateRecentAGMethod = environment.updateRecentAG.method;

  const userId = this.dataStore.getUserDetailsValue().getUserId();

  const queryParams = {
    'ag': groupName,
    'userId': userId,
    'tenantId': TENANT_ID.tenantId
  };

  if (queryParams['ag'] !== undefined) {

   this.updateRecentAGSubscription = this.updateRecentAGService.updateRecentlyViewedAG(queryParams, updateRecentAGUrl, updateRecentAGMethod).subscribe(
     response => {
       this.recentTiles = response.data.response[0].recentlyViewedAg;
    },
    error => {

    });
  }
}

instructParentToCloseAssetGroup (assetGroupName: any) {
  debugger
    this.router.navigate(['/compliance/compliance/compliance-dashboard'], {
      relativeTo: this.activatedRoute,
      queryParams: {ag: assetGroupName
      }
    })
    this.closeModal();
}


checkRecentlyViewed(name: any) {

  if (!name || !this.selectedTabName) {
    return false;
  }
  const tiles = this.recentTiles.map((item: any) => {
    return item['ag'];
  });
  if (this.selectedTabName.toLowerCase() === 'recently viewed') {
     if (tiles.includes(name.name.toLowerCase())) {
       return true;
     } else {
       return false;
     }
  } else {
    return false;
  }

}

  /**
 * This function navigates the page mentioned  with a ruleID
 */
  navigatePage(data1: any, data2: any) {
    /**
     * selectAsset function closes the modal window and update the asset group
     * after that router.navigate is used to navigate
     */

  try {
    const clickText = data1;
    const apiTarget = {'TypeAsset' : 'TotalAsset'};
          /**
           * Router navigation is not working , need to check --> Trinanjan
           */

          if (clickText.toLowerCase() === 'total asset' ) {
              /**
               * This router.navigate function is added By Trinanjan on 31.01.2018
               * This router.navigate function first closes the modal window and then navigates to the path specified
               */
              const eachParams = {};
              let newParams = this.utils.makeFilterObj(eachParams);
              newParams = Object.assign(newParams, apiTarget);
              newParams['ag'] = data2;


              this.router.navigate([{
                  outlets: {
                      modal: null
                  }
              }],
                  {
                      relativeTo: this.activatedRoute.parent,
                      queryParamsHandling: 'merge'
                  }).then(() => this.router.navigate(['compliance', 'assets' , 'asset-list' ], {queryParams: newParams, queryParamsHandling: 'merge'}));
          }
  } catch (error) {
    this.logger.log('error', error);
  }
}

  ngOnDestroy() {
    try {
      if (this.assetGroupSubscription) { this.assetGroupSubscription.unsubscribe(); }
      if (this.assetGroupSubscription) { this.domainSubscription.unsubscribe(); }
      this.subscriptionToAssetGroup.unsubscribe();
    } catch (error) {
      this.logger.log('error', error);
    }
  }
}