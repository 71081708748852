<div class="overlay-vertical-center" (click)='$event.stopPropagation()'>
  <div class="overlay-innerwrap">
      <div class="event-container">
          <article class="header-asset flex flex-between flex-align-center">
              <div class="header-title">
                  Asset Groups
              </div>
              <a *ngIf="!hideCloseButton">
                  <div class="delete-title">
                      <img class="delete-icon" src="assets/png/delete.png">
                  </div>
              </a>
          </article>
          <article class="sub-header-asset flex flex-between flex-align-center">
              <div class='asset-tabs-outer-wrap'>
                  <!-- <app-asset-group-tabs *ngIf='assetTabName' (updateTabs)='updateTab($event)' [(selectedTabName)]='selectedTabName' [assetTabName]='assetTabName'></app-asset-group-tabs> -->
              </div>
              <div class="search">
                  <!-- <app-asset-group-search (searchQuery)='getSearch($event)'></app-asset-group-search> -->
              </div>
          </article>
          <article class="asset-tile-container">
              <div class="asset-tiles-wrapper flex flex-between">
                  <div *ngIf='showError' class='error-msg absolute'>Unable to fetch asset groups!</div>
                  <section class="asset-tile-content flex flex-wrap" *ngIf='loaded'>
                      <!-- <div class="container relative" [class.left-most]='i%3 == 0' [hidden]="!((selectedTabName && selectedTabName.toLowerCase())==(assetTabName[0] && assetTabName[0].toLowerCase()) || (selectedTabName && selectedTabName.toLowerCase())==(assettile.type && assettile.type.toLowerCase()) || checkRecentlyViewed(assettile) ) " [class.active]='thisAssetTile' [class.dark-bg]='thisAssetTile ==  assettile.name' [class.currently-selected]='selectedGroup==assettile.name' *ngFor="let assettile of ( assetTiles | searchFilter: returnedSearch  ) | orderBy: { property: 'displayname', direction: 1}  ; let i = index; " (click)='assetTileClicked(assettile.name, assettile); getCurrentAssetInfo(assettile)'> -->
                          <!-- <div class="name-title">{{assettile.displayname}}</div>
                          <div *ngIf='assettile.description' class="description line-clamp">{{assettile.description}}</div>
                          <div *ngIf='!assettile.description' class="description line-clamp">Select this asset group to fetch data under this asset group</div>
                          <div class='absolute select-tile' (click)='$event.stopPropagation(); getCurrentAssetInfo(assettile); selectAsset(assettile); ' ><span *ngIf='selectedGroup!=assettile.name'>Select</span><span *ngIf='selectedGroup==assettile.name'>Selected</span></div>
                          <div *ngIf='assettile.domains && assettile.domains.length && selectedGroup == assettile.name ' [class.hide]='true' tabindex="1" (blur)='assettile.showDomainInfo = false' class='absolute domain-icon flex flex-align-center'>
                              <div class='domain-number'>{{assettile.domains.length}}</div>
                              <div (click)='assettile.showDomainInfo=!assettile.showDomainInfo'>
                                  <img *ngIf='thisAssetTile !=  assettile.name' src='../assets/icons/earth-grid-symbol-grey.svg'>
                                  <img *ngIf='thisAssetTile ==  assettile.name' src='../assets/icons/earth-grid-symbol-blue.svg'>
                              </div>
                          </div> -->
                      <!-- </div> -->
                  </section>
                  <section class="asset-details-content flex flex-col flex-between" *ngIf='thisAssetTile && loaded && !showError'>
                      <div class="transformState" [class.transformBack]="thisAssetTile">
                          <!-- <app-asset-group-details [assetDetailsState]='assetDetailsState' [detailsVal]='assetDetailTiles' (navigatePage)="navigatePage($event,thisAssetTile)"></app-asset-group-details> -->
                      </div>
                      <a>
                          <div class='center side-btn-wrap'>
                              <!-- <app-button (click)='sendAssetName = thisAssetTile; setDefault();'> SET AS MY DEFAULT </app-button> -->
                          </div>
                      </a>
                  </section>
              </div>
              <div class="loader" *ngIf='!loaded'></div>
          </article>
      </div>
  </div>
</div>

<!-- <div class="modal modal2 fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
<div class=" modal-dialog modal-dialog2">
    <div class="modal-content text-center">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="staticBackdropLabel">Asset Groups</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center pt-2 tab-box">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#asset-pills-1" type="button"
              role="tab" aria-controls="pills-home" aria-selected="true">All </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#asset-pills-2"
              type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Recently Viewed</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="asset-pills-1" role="tabpanel" aria-labelledby="pills-home-tab"
            tabindex="0">
            <div class="row mb-4">
              <div class="col-md-4 ">
                <a>
                  <div class="knowledge-box modal-tab card-div">
                    <div class="row">
                      <div class="col-12 pt-2 text-start"><b>Aws All</b></div>

                      <div class="col-12 text-start">
                        Asset Group to segregate all data related to aws.
                      </div>
                      <div class="col-12"><span class="border btn btn-sm float-end p-1 small text-white">Selected</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-4 modal-tab">
                <a>
                  <div class="knowledge-box modal-tab card-div">
                    <div class="row">
                      <div class="col-12 pt-2 text-start"><b> All Azure</b></div>

                      <div class="col-12 text-start">

                      </div>
                      <div class="col-12"><span class="border btn btn-sm float-end p-1 small text-white">Selected</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="asset-pills-2" role="tabpanel" tabindex="0">
            <div class="row mb-4">
              <div class="col-md-4 ">
                <a>
                  <div class="knowledge-box modal-tab card-div">
                    <div class="row">
                      <div class="col-12 pt-2 text-start"><b>Aws All</b></div>

                      <div class="col-12 text-start">
                        Asset Group to segregate all data related to aws.
                      </div>
                      <div class="col-12"><span class="border btn btn-sm float-end p-1 small text-white">Selected</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-4 modal-tab">
                <a>
                  <div class="knowledge-box modal-tab card-div">
                    <div class="row">
                      <div class="col-12 pt-2 text-start"><b>Aws All</b></div>

                      <div class="col-12 text-start">
                        Asset Group to segregate all data related to aws.
                      </div>
                      <div class="col-12"><span class="border btn btn-sm float-end p-1 small text-white">Selected</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="#" class="btn btn-green btn-sm ms-2"> Set as My Default</a>
      </div>
    </div>
  </div>
</div> -->