<div class="header-description" title="{{resourceType}}/{{decodedResourceId}}"> ({{resourceType}}/{{decodedResourceId}})</div>
<section>
    <app-pacman-policy-violations [resourceId]='resourceId' [resourceType]='resourceType' [pageLevel]="pageLevel">
    </app-pacman-policy-violations>
</section>
<section>
    <div class="container-fluid mt-3">
        <div class="row">
            <div class="col-md-6 mb-2" *ngIf='!hideContainer[0]'>
                <div class="card pichartbox asset-summary p-3">
                    <h6>Asset Summary</h6>
                    <app-asset-list-detail-summary *ngIf='assetSummaryData' [dataArray]='assetSummaryData'></app-asset-list-detail-summary>
                </div>
            </div>
            <div class="col-md-6 px-2 mb-2" *ngIf='!policyValue || !hostValue'>
                <div class="card pichartbox quick-action p-3 pb-1 me-1">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <div class="graph-chart-cont relative" id="statsDoughnut" *ngIf="!policyValue">
                                    <h6 *ngIf='policyAvailable[0]'>Policy Violation Summary</h6>
                                    <app-doughnut-chart *ngIf='policyAvailable[0]' [chartContId]="'statsDoughnut'"
                                        [graphData]="policyData" [graphWidth]="widgetWidth" [flexTrue]="true"
                                        [graphHeight]="widgetHeight" [MainTextcolor]='MainTextcolor' [innerRadious]='innerRadious'
                                        [outerRadious]='outerRadious' [strokeColor]="strokeColor"
                                        (navigatePage)="navigateDataTable($event)"></app-doughnut-chart>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="graph-chart-cont relative" id="statsDoughnuttwo" *ngIf="!hostValue">
                                    <h6 *ngIf='policyAvailable[1]'>Host Vulnerability Summary</h6>
                                    <app-doughnut-chart *ngIf='policyAvailable[1]' [chartContId]="'statsDoughnuttwo'"
                                        [graphData]="policyData" [graphWidth]="widgetWidth" [flexTrue]="true"
                                        [graphHeight]="widgetHeight" [MainTextcolor]='MainTextcolor' [innerRadious]='innerRadious'
                                        [outerRadious]='outerRadious' [strokeColor]="strokeColor"
                                        (navigatePage)="navigateDataTable($event)"></app-doughnut-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
 <section>
    <div class="container-fluid py-1" *ngIf='!hideHostVulnerabilities'>
        <div class="row">
            <div class="col-12">
                    <div class="container-fluid p-1">
                        <app-host-vulnerabilities (errorOccured)='hideHostVulnerabilities = true' [resourceId]='resourceId'></app-host-vulnerabilities>
                    </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid mt-3" *ngIf='!showCpuData'>
        <div class="row">
            <div class="col-md-12 mb-2">
                <div class="card pichartbox asset-summary p-3">
                    <!-- <h6>CPU Utilization</h6> -->
                        <app-multiline-asset-trend *ngIf="policyAvailable[2]" [subHeadTitle]="'CPU Utilization'" [id]="'cpuUtilization'"
                            [showLegend]="showGraphLegend" [yAxisLabel]="yAxisLabel" [graphLinesData]="cpuData"></app-multiline-asset-trend>
                    <!-- <ul *ngIf='policyAvailable[2]' class="flex flex-row flex-center legends-wrapper">
                        <li *ngFor="let line of cpuData; let i = index;" class="flex flex-row flex-align-center">
                            <div class="legend-color" [ngStyle]="{'background-color': colorSetCpu[i]}"></div>
                            <div class="flex flex-col flex-center legend-text capitalize">{{line.values[0].keys[i]}}</div>
                        </li>
                    </ul> -->
                </div>
            </div>
           
        </div>
    </div>
</section>
<section>
    <div class="col-md-12 px-2 mb-2" *ngIf='diskData'>
        <div class="row">
            <div class="col-6">
                <div class="card pichartbox asset-summary p-3">
                    <h6>Disk Utilization</h6>
                    <div class="disk-container-wrapper flex-around">
                        <ul *ngIf='policyAvailable[3]' class="flex flex-start disk-container-ul">
                            <li class="graph-chart-cont relative padding-wrapper"
                                *ngFor="let data of diskData ; let i = index " id="d3{{i}}">
                                <app-doughnut-chart [chartContId]="'d3'+i" [graphData]="data"
                                    [graphWidth]="widgetWidth1" [flexTrue]="true" [graphHeight]="widgetHeight1"
                                    [MainTextcolor]='MainTextcolor' [innerRadious]='innerRadious1'
                                    [outerRadious]='outerRadious1' [strokeColor]="strokeColor"></app-doughnut-chart>
                                <div class="donutDetails">
                                       Name: {{data.name}}
                                    <div class="details">
                                        <div class="enclosure flex flex-between">
                                            Used Space: {{data.size}}
                                        </div>
                                        <div class="enclosure flex flex-between">
                                            Available Space: {{data.free}}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                    <app-access-groups [resource]='accessGroupData'></app-access-groups>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1" *ngIf="filteredData == true">
        <div class="row">
            <div class="col-12">
                    <div class="container-fluid p-1">
                        <app-attribute [pageLevel]="pageLevel" [dataObj]='genericAttributes'></app-attribute>
                    </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1" *ngIf='!hideOpenPorts'>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <app-open-ports (errorOccured)='hideOpenPorts = true' [resourceId]='resourceId' [resourceType]='resourceType' ></app-open-ports>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1">
            <div class="col-12">
                <div class="row">
                <div class="col-6" *ngIf='!installedSoftwares'>
                    <div class="card">
                        <app-installed-softwares (errorOccured)='installedSoftwares = true' [resourceId]='resourceId'
                            [resourceType]='resourceType'></app-installed-softwares>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card">
                        <div class="notifications-details flex flex-between flex-wrap">
                            <h6>AWS Notifications</h6>
                            <div class="col-12 aws-div">
                                <div class="col-sm-4 col-md-4 col-lg-4 pb-3">
                                    <div class="card text-center mb-3 summaryDiv">
                                        <div class="card-body">
                                            <div class="status flex flex-align-center ports-div" *ngIf='awsDetailsData'
                                                [ngClass]="{'link-text underline-link-num': awsDetailsData[0].count >0}"
                                                (click)="awsDetailsData[0].count > 0  && navigateToAWSNotifications('open')">
                                                <div class="statusSymbol open"></div>
                                                <span class="status-text">{{awsDetailsData[0].status}}</span>
                                                <div class="statusValue">{{awsDetailsData[0].count}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4 pb-3">
                                    <div class="card text-center mb-3 summaryDiv">
                                        <div class="card-body">
                                            <div class="status flex flex-align-center ports-div" *ngIf='awsDetailsData'
                                                [ngClass]="{'link-text underline-link-num': awsDetailsData[1].count >0}"
                                                (click)="awsDetailsData[1].count > 0  && navigateToAWSNotifications('closed')">
                                                <div class="statusSymbol closed"></div>
                                                <span class="status-text">{{awsDetailsData[1].status}}</span>
                                                <div class="statusValue">{{awsDetailsData[1].count}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4 pb-3">
                                    <div class="card text-center mb-3 summaryDiv">
                                        <div class="card-body">
                                            <div class="status flex flex-align-center ports-div" *ngIf='awsDetailsData'
                                                [ngClass]="{'link-text underline-link-num': awsDetailsData[2].count >0}"
                                                (click)="awsDetailsData[2].count > 0  && navigateToAWSNotifications('upcoming')">
                                                <div class="statusSymbol scheduled"></div>
                                                <span class="status-text">{{awsDetailsData[2].status}}</span>
                                                <div class="statusValue">{{awsDetailsData[2].count}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                               <div class="loader" *ngIf="!showLoader[5]"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1" *ngIf="!hideContainer[5]">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <h6>IP Address</h6>
                    <app-asset-contents [resource]="dataObj['IP Address']"></app-asset-contents>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1" *ngIf="!hideContainer[2]">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <h6>Created By</h6>
                    <app-asset-contents [resource]='dataObj.Creators'></app-asset-contents>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1" *ngIf="!hideContainer[3]">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <h6>AWS Metadata</h6>
                    <app-asset-contents [resource]="dataObj['AWS Metadata']"></app-asset-contents>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1">
        <div class="col-12">
            <div class="row">
                <div class="col-6" *ngIf="!hideContainer[6]">
                    <div class="card cost-content">
                        <h6>Cost</h6>
                        <app-asset-contents [resource]="costData"></app-asset-contents>
                    </div>
                </div>
                <div class="col-6" *ngIf='!hideContainer[4]'>
                    <app-filter-info *ngIf='tagsVariable' [tags]='tagsArray'></app-filter-info>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid py-1" *ngIf='!hideContainer[4]'>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <h6 class="quick-action-header">Quick Actions</h6>
                    <div class="actions-wrappers add-margin" *ngIf="showOppositeEmail">
                        <header class="recommend-flip flex-between flex flex-align-center">
                            <div class="header-text">
                                + Send Email
                            </div>
                            <div class="delete-img" (click)="sendEmail()">
                                <img style="height: 1.8em;" src="assets/png/delete.png">
                            </div>
                        </header>
                        <section class="text-wrapper" *ngIf="!showTransactionEmail">
                            <form novalidate [formGroup]="userEmail" (keydown.enter)="$event.preventDefault()">
                                <label>
                                    <div class="text-wrapper-header">To</div>
                                    <ul class="input-wrapper">
                                        <li class="list-inputs flex flex-align-center" *ngFor="let data of emailArray;let i = index">
                                            {{data}}<img class="crossmark-icon" (click)='removeData(i)' src="assets/png/delete.png">
                                        </li>
                                        <input type="text" placeholder="Enter User's Email" [(ngModel)]='queryValue' (keyup)=filter() class="inputarea-container" formControlName="ename" (keydown)="keyDown($event)">
                                    </ul>
                                </label>
                                <div class="suggestion-wrapper">
                                    <div class="suggestions" *ngIf="filteredList.length > 0">
                                        <ul class="suggestion-list">
                                            <li class="lists-suggestion" *ngFor="let item of filteredList; let i = index " [class.active]="i == arrowkeyLocation" (click)="select(item)" (keydown)="keyEvent($event, item)">
                                                <a>{{item}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="error-reason" *ngIf="!invalid">
                                    Email is required
                                </div>
                                <div class="textarea-button">
                                    <button class="button-textarea" type="button" (click)='onSubmitemail()'>SEND
                                    </button>
                                </div>
                            </form>
                        </section>
                        <div class="circle-container" *ngIf="showTransactionEmail">
                            <div class="circle-loader" [class.load-complete-success]='showLoadcompleteEmail && checkEmail' [class.load-complete-failure]='showLoadcompleteEmail && !checkEmail'>
                                <div *ngIf='checkEmail' class="checkmark draw" [class.checkmark-show]='showLoadcompleteEmail'></div>
                                <div *ngIf='!checkEmail' class="crossmark draw" [class.crossmark-show]='showLoadcompleteEmail'></div>
                            </div>
                        </div>
                        <div class="error_msg_email" *ngIf='showLoadcompleteEmail && !checkEmail'>Email not sent
                        </div>
                        <div class="success_msg_email" *ngIf='showLoadcompleteEmail && checkEmail'>Email sent successfully
                        </div>
                        <div class='border-bottom'></div>
                    </div>
                    <div class='buttons-wrapper'>
                        <div class='flex flex-col flex-center button-parent' *ngIf="showEmail && this.configurations && this.configurations.optional.assetDetails.ASSET_DETAILS_FROM_ID && this.configurations.optional.assetDetails.ASSET_DETAILS_TEMPLATE_URL && !showOppositeEmail">
                            <app-button-icon (onClickInner)="sendEmail()" [iconSrc]="'assets/icons/email.svg'">Email</app-button-icon>
                        </div>
                        <div class='flex flex-col flex-center button-parent' *ngIf="resourceType == 'ec2'">
                            <app-button-icon [disabled]='buttonDisable == false'>Start/Stop Server</app-button-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  