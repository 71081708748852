import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';

@Component({
  selector: 'app-search-download',
  templateUrl: './search-download.component.html',
  styleUrls: ['./search-download.component.css']
})
export class SearchDownloadComponent implements OnInit {
  
  constructor(
    private router: Router,
    public assetGroupObservableService: AssetGroupObservableService,
    private activatedRoute: ActivatedRoute,
    private workflowService: WorkflowService
  ) {
    this.assetGroupSubscription = this.assetGroupObservableService
      .getAssetGroup()
      .subscribe(assetGroupName => {
        this.selectedAssetGroup = assetGroupName;
        this.searchQuery = '';
        this.searchCalled();
      });
  }

  searchQuery = '';
  private assetGroupSubscription: Subscription;
  selectedAssetGroup :any;

  @Input() subHeadTitle :any;
  @Input() routeTo :any;
  @Input() routeParams: any={};
  @Input() arrow :any;
  @Input() contextualMenuAbsent :any;
  @Input() imagePresent :any;
  @Input() dropDowndata :any;
  @Input() showSearch :any;
  @Input() selectedDD :any;
  @Input() popRows :any;
  @Input() complianceDropdowns :any;
  @Input() dropDownpresent :any;
  @Output() dataSent = new EventEmitter();
  @Output() searchTxt = new EventEmitter<string>();
  @Output() rowText = new EventEmitter<string>();
  @Output() enterPressed = new EventEmitter();
  showdata  :boolean= false;
  @Input() tableIdAppend :any;
  @Input() pageLevel :any;
  @Input() helpId :any;

  private urlToRedirect :any;

  loaded: boolean =false;

  ngOnInit() {
    this.loaded = true;
    if (this.dropDowndata !== undefined) {
      this.showdata = true;
    }
    this.urlToRedirect = this.router.routerState.snapshot.url;
  }

  selectionDD(val :any) {
    this.dataSent.emit(val);
  }

  searchCalled() {
    this.searchTxt.emit(this.searchQuery);
  }

  keyDownFunction(event :any) {
    // if (event.keyCode === 13) {
    //   this.enterPressed.emit(this.searchQuery);
    // }

    if(this.searchQuery !== '') {
      if(event.type === 'click') {
        this.enterPressed.emit(this.searchQuery);
      }
    }
  }

  navigateTo() {
    if (this.routeTo !== undefined) {
      this.workflowService.addRouterSnapshotToLevel(
        this.router.routerState.snapshot.root
      );
      this.router.navigate(['../', this.routeTo], {
        relativeTo: this.activatedRoute,
        queryParams: this.routeParams,
        queryParamsHandling: 'merge'
      });
    }
  }

  emitRowClicked(rowText :any) {
    this.rowText.emit(rowText);
  }

  ngOnDestroy() {
    if (this.assetGroupSubscription) {
      this.assetGroupSubscription.unsubscribe();
    }
  }

  callHelp() {
    const newParams = { widgetId: this.helpId };
    this.router.navigate(
      ['/pl', { outlets: { helpTextModal: ['help-text'] } }],
      { queryParams: newParams, queryParamsHandling: 'merge' }
    );
  }
}
