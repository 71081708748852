import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
// import { KeycloakProfile } from 'keycloak-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  // private readonly instance: Keycloak;
  // readonly onRefreshToken$ = new Subject<void>();
  // config = {
  //       'url': 'https://keycloak.ustpace.com/auth',
  //       'realm': 'Edgeops-Dev',
  //       'clientId': 'Edgeops-ui',
  //       'enable': true,
  //       'public-client': true,
  //       "ssl-required": "none"
  //     };
  // config = {
  //       'url': 'http://localhost:8080/',
  //       'realm': 'compliance',
  //       'clientId': 'compliance',
  //       'enable': true,
  //       'public-client': false,
  //       "ssl-required": "none"
  //     };
  readonly onRefreshToken$ = new Subject<void>();
  defaultConfig = {
        'url': this.getKeyCloakUrl(),
        'realm': this.getKeyCloakRealm(),
        'clientId': this.getKeyCloakClientId(),
        'enable': true,
        'public-client': true,
        "ssl-required": "none"
  };
  // private readonly instance = new Keycloak(localStorage.getItem('keycloakParams') ? JSON.parse(localStorage.getItem('keycloakParams')) : this.defaultConfig);
  private readonly instance = new Keycloak(
    JSON.parse(localStorage.getItem('keycloakParams') ?? JSON.stringify(this.defaultConfig))
);
  constructor() {
    // this.instance = new Keycloak(this.config);
    this.instance.onAuthRefreshSuccess = () => {
      this.setTimeoutToken();
      console.log('The Keycloak token has been refreshed successfully');
    };
    this.instance.onAuthRefreshError = () => {
      this.setTimeoutToken();
      console.error('Cannot refresh the Keycloak authorization token.');
    };
    this.instance.onReady = (result: boolean) => {
      if(result){
        this.setTimeoutToken();
      }
      console.log('The Keycloak service is ready with result =' + result);
    };
    this.instance.onTokenExpired = () => {
      console.log('The Keycloak token has expired.');
      this.setTimeoutToken();
  };
    this.instance.onAuthLogout = () => {
      console.log('The Keycloak token has expired.');
      this.setTimeoutToken();
    };
  }
  
  setTimeoutToken(){
    // const timeOut = setTimeout(() => {
    //   this.updateToken();
    //   clearTimeout(timeOut);
    // }, ((this.instance?.idTokenParsed?.exp - this.instance?.idTokenParsed?.iat) * 1000) / 2);
  }
  
  updateToken(){
    this.instance.updateToken(-1).then(() => {
      console.log('The Keycloak token has been refreshed.');
      this.onRefreshToken$.next();
    });
  }

  /**
   * init and login
   */
  login(): Promise<any> {       
    this.instance.clearToken();     
    return new Promise((resolve, reject) => {
      const token:any = localStorage.getItem('keyCloackToken');
      const refreshToken:any = localStorage.getItem('refreshkeyCloackToken');
      const urlObject = new URL(location.href);
      const urllocation= urlObject.origin+'/compliance/complianceloader';
      // const urllocation= urlObject.origin+'/compliance/compliance-dashboard?ag=aws';
      //  const urllocation= urlObject.origin+'/compliance/compliance/compliance-dashboard?ag=aws';
      this.instance
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          token: token,
          refreshToken: refreshToken,
          redirectUri: urllocation,
          silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso'
        })
        .then(() => {
          if (this.instance?.token) {
            localStorage.setItem('keyCloackToken', this.instance.token);
            localStorage.setItem('sessionId', this.instance.token);
          }
          if (this.instance?.refreshToken) {
            localStorage.setItem('refreshkeyCloackToken', this.instance.refreshToken);
          }
          localStorage.setItem('isLoggedIn', JSON.stringify(true));
          localStorage.setItem('loginType', 'ssoEnabled');
          resolve(this.instance);
           
        })
        .catch(reject);
    });
  }

  getTokenApi() {
    return this.instance.token || '';
  }
  getRefreshToken() {
    return this.instance.refreshToken || '';
  }
  getKeyCloakUrl(): string{
    switch(true){
      case window.location.href.includes('bca.ustpace'):
        return 'https://bca.ustpace.com/auth';
      case window.location.href.includes('bcbsa.ustpace'):
        return 'https://bcbsa.ustpace.io/auth';        
      default:
          // return 'https://bca.ustpace.com/auth'
        return 'https://keycloak.ustpace.com/auth'
    }
  }

  getKeyCloakRealm(): string{
    switch(true){
      case window.location.href.includes('bca.ustpace'):
      case window.location.href.includes('bcbsa.ustpace'):
        return 'DAgility';
      case window.location.href.includes('demo.ustpace'):
        return 'Edgeops-Demo';    
      default:
        return 'Edgeops-Dev'
    }
  }
  getKeyCloakClientId(): string{
    switch(true){
      case window.location.href.includes('bca.ustpace'):      
      case window.location.href.includes('bcbsa.ustpace'):
        return 'compliance-ui';
      case window.location.href.includes('prod.ustpace'):
        return 'Production-Edgeops-ui';
      default:
        return 'Edgeops-ui'
    }
  }

  // getKeyCloakRealm(): string{
  //   switch(true){
  //     case window.location.href.includes('bca.ustpace'):
  //     case window.location.href.includes('bcbsa.ustpace'):
  //       return 'DAgility';
  //     case window.location.href.includes('demo.ustpace'):
  //       return 'Edgeops-Demo';     
  //     default:
  //        return 'DAgility'
  //       return 'Edgeops-ui'
  //   }
  // }
  // getKeyCloakClientId(): string{
  //   switch(true){
  //     case window.location.href.includes('bca.ustpace'):       
  //     case window.location.href.includes('bcbsa.ustpace'):
  //       return 'compliance-ui';
  //     case window.location.href.includes('prod.ustpace'):
  //       return 'Production-Edgeops-ui';
  //     default:
  //       return 'compliance-ui'
  //   }
  // }
  /**
   * logout
   */
  // logout(): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     if (this.instance.authenticated) {
  //       debugger
  //       this.instance
  //         .logout()
  //         .then(() => {
  //           resolve();
  //         })
  //         .catch(() => {
  //           reject('Failed to logout');
  //         });
  //     } else {
  //       debugger
  //       resolve();
  //     }
  //   });    
  // }
  logout(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.instance.authenticated) {
        debugger
        this.instance.logout()
          .then(() => {
            console.log('Logged out successfully');
            resolve();
          })
          .catch(error => {
            console.error('Logout failed:', error);
            reject(error);
          });
      } else {
        console.log('Already logged out');
        resolve();
      }
    });
  }
  /**
   * get logged in user info
   */
  // getUserInfo(): Promise<KeycloakProfile> {
  //   return new Promise((resolve, reject) => {
  //     this.instance.loadUserProfile().then(resolve).catch(reject);
  //   });
  // }
  /**
   * Keeping this method for testing
   * @param msg 
   */
  private log(msg: string) {
    console.log(msg);
  }
}
