<div class="btn-group">
  <button class="btn btn-secondary bg-light text-dark form-select aws" type="button" id="dropdownMenuButton"
    data-bs-toggle="dropdown" aria-expanded="false">
    {{ assetGroupDisplayName }}
    <img *ngIf="assetGroupDisplayName == 'aws all'" src="assets/icons/aws-color.svg" />
    <img *ngIf="assetGroupDisplayName == 'Azure'" class="default-asset-group-icon"
      src="assets/icons/azure-color.svg" width="20px" />
    <img *ngIf="(assetGroupDisplayName !== 'aws all' && assetGroupDisplayName !== 'Azure')"
      class="default-asset-group-icon" src="assets/icons/asset-group-magenta.svg" width="20px" />
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <li *ngFor="let group of recentTiles" (click)="changeAg(group)">
      <a class="dropdown-item azur">{{ group.displayName }}
        <img *ngIf='(group.providers && group.providers.length == 0) || !group.providers'
          class="default-asset-group-icon" src="assets/icons/asset-group-magenta.svg" width="20px" />
        <img *ngIf='group.providers && group.providers.length == 1' class="default-asset-group-icon"
          src="assets/icons/{{group.providers[0].provider}}-color.svg" width="20px" />
        <img *ngIf='group.providers && group.providers.length > 1' class="default-asset-group-icon"
          src="assets/icons/multi-cloud.svg" width="20px" />
      </a>
    </li>
    <li class="btn-green" (click)='openModal();'>View More</li> 
    <!-- <li><button class="btn btn-green" (click)='$event.stopPropagation(); openAgModal();'>View More</button></li> -->
  </ul>
</div>

<div #staticBackdrop class="modal modal2 fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class=" modal-dialog modal-dialog2">
    <div class="modal-content text-center">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="staticBackdropLabel">Asset Groups</h5>
        <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center pt-2 tab-box modal-div-scroll">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <app-asset-group-tabs *ngIf='assetTabName' (updateTabs)='updateTab($event)' [(selectedTabName)]='selectedTabName' [assetTabName]='assetTabName'></app-asset-group-tabs>
        </ul>
        <!-- <div *ngIf='showError' class='error-msg absolute'>Unable to fetch asset groups!</div> -->
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="asset-pills-1" role="tabpanel" aria-labelledby="pills-home-tab"
            tabindex="0">
                  <div class="asset-tile-content flex flex-wrap cards-main-div" *ngIf='loaded'>
                    <div class="container relative asset-group-div" [class.left-most]='i%3 == 0' [hidden]="!((selectedTabName && selectedTabName.toLowerCase())==(assetTabName[0] && assetTabName[0].toLowerCase()) || (selectedTabName && selectedTabName.toLowerCase())==(assettile.type && assettile.type.toLowerCase()) || checkRecentlyViewed(assettile) ) " [class.active]='thisAssetTile' [class.dark-bg]='thisAssetTile ==  assettile.name' [class.currently-selected]='selectedGroup==assettile.name' *ngFor="let assettile of ( assetTiles | searchFilter: returnedSearch  ) | orderBy: { property: 'displayname', direction: 1}  ; let i = index; " (click)='assetTileClicked(assettile.name); getCurrentAssetInfo(assettile)'>
                        <div class="name-title">{{assettile.displayname}}</div>
                        <div *ngIf='assettile.description' class="description line-clamp">{{assettile.description}}</div>
                        <div *ngIf='!assettile.description' class="description line-clamp">Select this asset group to fetch data under this asset group</div>
                        <!-- <div class='absolute select-tile' (click)='$event.stopPropagation(); getCurrentAssetInfo(assettile); selectAsset(assettile);'><span *ngIf='selectedGroup!=assettile.name'>Select</span><span *ngIf='selectedGroup==assettile.name'>Selected</span></div> -->
                    </div>
                  </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <app-button (click)='sendAssetName = thisAssetTile; setDefault();'> SET AS MY DEFAULT </app-button> -->
        <button class="btn default-btn btn-sm ms-2" (click)='sendAssetName = thisAssetTile; setDefault();'> Set as My Default</button>
      </div>
    </div>
  </div>
</div>