<!-- Policy Violation Section Start -->
<section class="policyviotable">
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="tab-box">
          <!--Table Container start here -->
          <div class="container-fluid mt-2 p-1 policy-violation dataTable-section-height">
            <div class="row" *ngIf='searchableHeader'>
              <div class="col-md-4 pv-left">
                <!-- <h4>Policy Violation</h4> -->
                <!-- <div class="policy-count">
                  Total of {{ currentTableData.length }} Policies
                </div> -->
              </div>
            </div>
            <div class="row" *ngIf='!searchableHeader || notificationHeader'>
              <div class="col-md-6">
                <!-- <h6 class="card-title">{{dataHead}}</h6> -->
              </div>
              <!-- <div class="col-md-6 filtersearch" *ngIf="isFilterRquiredOnPage">
                <app-generic-page-filter [filterId]="1" [filterArray]="filterArray" (onFilterValueChange)="updateUrlWithNewFilters($event)"></app-generic-page-filter>
              </div> -->
            </div>
            <!-- <div class='' *ngIf='filterArray.length'>
              <app-filtered-selector (updateFilterArray)='updateUrlWithNewFilters($event)' [(filteredArray)]='filterArray'></app-filtered-selector>
          </div> -->
          <!-- <div class="download-div"> -->
            <!-- <button class="download" (click)="exportAsExcel()"><a><i class="bi bi-download p-2"></i></a></button> -->
            <!-- <app-search-download *ngIf="outerArr && outerArr.length > 0" [tableIdAppend]="tableIdAppend" (rowText)="emitRowText($event)" [popRows]="popRows"
              [showSearch]="searchableHeader" [subHeadTitle]="dataHead" (searchTxt)="searchCalled($event)"
              (enterPressed)="triggerSearch()"></app-search-download>
          </div> -->
            <div *ngIf="errorValue == 0" class="loader-container">
              <div class="loader">
                <span></span>
              </div>
            </div>
            <article class="error_handling" *ngIf="errorValue == -1 && errorMessage">
              <div class="error_msg">
                <app-error-message *ngIf="errorValue == -1 && errorMessage"
                  [selectedValue]="errorMessage"></app-error-message>
              </div>
            </article>
            <br>
            <!-- Data Table start here -->
            <!-- <div class="download"> -->
              <!-- <a href="#"><i class="bi bi-download p-2"></i></a> -->
              <!-- <app-title-burger-head *ngIf="outerArr && outerArr.length > 0" [tableIdAppend]="tableIdAppend" (rowText)="emitRowText($event)" [popRows]="popRows"
                [showSearch]="searchableHeader" [subHeadTitle]="dataHead" (searchTxt)="searchCalled($event)"
                (enterPressed)="triggerSearch()"></app-title-burger-head>
            </div> -->
            <div  #TABLE>
              <div class="download-div">
                <app-search-download *ngIf="outerArr && outerArr.length > 0" [tableIdAppend]="tableIdAppend" (rowText)="emitRowText($event)" [popRows]="popRows"
                  [showSearch]="searchableHeader" [subHeadTitle]="dataHead" (searchTxt)="searchCalled($event)"
                  (enterPressed)="triggerSearch()"></app-search-download>
              </div>
            <table datatable [dtOptions]="dtOptions" *ngIf="
                errorValue > 0 &&
                loaded &&
                filteredColumns.length > 0 &&
                sortArr.length > 0 
              " id="policy-violation" class="table nowrap policy-violation-table table-hover" width="100%" #datatable>
              <!-- Severity dropdown selection ends-->
              <thead>
                <tr>
                  <th (click)="
                      sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header)"
                    *ngFor="let header of filteredColumns; let i = index">
                    {{ header }}
                    <span [class.up-arr-shown]="sortArr[i].showUp == true"
                      [class.down-arr-shown]="sortArr[i].showUp == false" class="list-sortable-arrow3"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let row of currentTableData
                      | searchFilter : searchPassed
                      | orderBy
                        : {
                            property: headerColName,
                            direction: direction,
                            childProperty: 'valText'
                          };
                    let i = index
                  ">
                  <td  [ngClass]="{
                      sorting_1: row[column]?.colName === sortSelectedColomn
                    }" *ngFor="let column of filteredColumns">
                    <a *ngIf='row[column]?.link && row[column]?.text'
                      (click)='currentRowData = row;goToDetails(currentRowData, column);(row[column].text === "Edit" || row[column].text === "Disable") ? $event.stopPropagation() :""'
                      class="table-trucate" data-bs-toggle="tooltip" title="{{ row[column]?.text }}"
                      [ngClass]="{ low: row[column]?.colName == 'Status' }">
                      <span [ngClass]="{
                          'low-dot':
                            row[column]?.text === 'medium' ||
                            row[column]?.text === 'low',
                          'high-dot':
                            row[column]?.text === 'high' ||
                            row[column]?.text === 'critical'
                        }" *ngIf="row[column]?.colName == 'Severity'">
                        <i class="bi bi-circle-fill"></i>
                      </span>
                      {{ row[column]?.text || "No Data" }}
                    </a>
                    <!-- {{row[column] | json}} -->
                    <a *ngIf='!row[column]?.link && row[column]?.colName!="nonDisplayableAttributes"' (click)='tableRowClicked(row, i)' data-bs-toggle="modal" data-bs-target="#modalForm"
                      class="table-trucate"  title="{{ row[column]?.text }}"
                      [ngClass]="{ low: row[column]?.colName == 'Status' }">
                      <span [ngClass]="{
                          'low-dot':
                            row[column]?.text === 'medium' ||
                            row[column]?.text === 'low',
                          'high-dot':
                            row[column]?.text === 'high' ||
                            row[column]?.text === 'critical'
                        }" *ngIf="row[column]?.colName == 'Severity'">
                        <i class="bi bi-circle-fill"></i>
                      </span>
                      {{ row[column]?.text || "No Data" }}
                    </a>
                    <a *ngIf='row[column]?.colName==="nonDisplayableAttributes"' (click)='tableRowClicked(row, i)' data-bs-toggle="modal" data-bs-target="#modalForm"
                    class="table-trucate"  title="{{  row[column]?.text.RuleId + ', ' + row[column]?.text.PolicyId  }}"
                    [ngClass]="{ low: row[column]?.colName == 'Status' }">
                    <span [ngClass]="{
                        'low-dot':
                          row[column]?.text === 'medium' ||
                          row[column]?.text === 'low',
                        'high-dot':
                          row[column]?.text === 'high' ||
                          row[column]?.text === 'critical'
                      }" *ngIf="row[column]?.colName == 'Severity'">
                      <i class="bi bi-circle-fill"></i>
                    </span>
                    {{ row[column]?.text.RuleId + ', ' + row[column]?.text.PolicyId }}
                  </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <!-- Table Pagination Strip -->
            <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
              <!-- <div class='checked-list' *ngIf='checkBox'>Selected: <span
                  class='checked-list-length'>{{checkedList.length}}</span>
              </div> -->
              <div class='pagination-strip'>
                <div class='me-3'> Showing {{firstPaginator}} to <span
                    *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                    entries</span>
                </div>
                <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                    class="bi bi-chevron-left"></i>
                </div>
                <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                    [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                </div>
              </div>
            </div>
            <!-- Data Table Ends here -->
          </div>
          <!-- Table Container ends here -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Policy Violation Section End -->

<!-- Popup -->
<section>
  <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-dark text-white">
          <h5 class="modal-title" id="exampleModalLabel">Additional Details</h5>

          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">
            <i class="bi bi-x-lg text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3 pt-2">
              <table width="100%">
                <tr>
                  <td width="25%">Policy Name :</td>
                  <td width="75%" class="text-green">
                    {{ rowObj["Policy Name"]?.text || null }}
                  </td>
                </tr>

                <tr>
                  <td>Issue ID :</td>
                  <td class="text-green">
                    {{ rowObj["Issue ID"]?.text || null }}
                  </td>
                </tr>

                <tr>
                  <td>Resource ID :</td>
                  <td>{{ rowObj["Resource ID"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Severity :</td>
                  <td>{{ rowObj["Severity"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Rule Category :</td>
                  <td>{{ rowObj["Rule Category"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Service Now ID :</td>
                  <td>{{ rowObj["ServiceNowID"]?.text || null }}</td>
                </tr>
                <tr>
                  <td>Account Name :</td>
                  <td>{{ rowObj["Account Name"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Account ID :</td>
                  <td>{{ rowObj["Account ID"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Region :</td>
                  <td>{{ rowObj["Region"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Application :</td>
                  <td>{{ rowObj["Application"]?.text || null }}</td>
                </tr>
                <tr>
                  <td>Environment :</td>
                  <td>{{ rowObj["Environment"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Created On :</td>
                  <td>{{ rowObj["Modified On"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Modified On :</td>
                  <td>{{ rowObj["Created On"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Status :</td>
                  <td>
                    <span class="compliant">{{
                      rowObj["Status"]?.text || null
                      }}</span>
                  </td>
                </tr>

                <tr>
                  <td>Asset Type :</td>
                  <td>{{ rowObj["Asset Type"]?.text || null }}</td>
                </tr>

                <tr>
                  <td>Description :</td>
                  <td>{{ rowObj["Description"]?.text || null }}</td>
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>