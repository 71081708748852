<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box">
                    <div class="container-fluid mt-2 p-1">
                        <div class="row">
                            <div class="col-md-10 mx-auto mt-4 mb-2" [hidden]="hideContent">
                                <div class="row">
                                    <div class="col-md-12 pt-4 ">
                                        <div class="text-bold text-center">
                                            <img class="my-4" src="assets/images/info-icon.svg">
                                            <h5>Delete Asset Group</h5>
                                        </div>
                                    </div>
                                    <div class="col-md-10 py-4 mt-4 text-center mx-auto">
                                        Are you sure to delete <span class="highlight-pink"><b class="asset-group-deletion">{{groupName}}</b></span>?
                                    </div>
                                </div>
                                <div class="col-12 mb-5">
                                    <div class="col-md-4 mx-auto my-4 text-center createsubmit">
                                        <a class="btn btn-border me-2">
                                            <button class="submit-btn" type="button" (click)="navigateBack()">
                                                <i class="bi bi-x fs-5"></i>
                                                Cancel
                                            </button>
                                        </a>
                                        <a class="btn btn-border me-2 btn-green">
                                            <button class="submit-btns" (click)="deleteAssetGroup()">
                                                <i class="bi bi-check fs-5"></i>
                                                Delete
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="pacman-alert" *ngIf="isAssetGroupDeletionFailed">
                                <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                    <span class="sa-x-mark animateXMark">
                                        <span class="sa-line sa-left"></span>
                                    <span class="sa-line sa-right"></span>
                                    </span>
                                </div>
    
                                <div class="pacman-alert-message">
                                    Failed in deleting asset group <span class="highlight-pink"><b class="asset-group-deletion">{{groupName}}</b></span> !!!
                                </div>
                                <div class="pacman-alert-button">
                                    <a class="btn btn-border">
                                    <button class="submit-btn" type="button" (click)="closeErrorMessage()"><i class="fa fa-remove"></i> CLOSE</button></a>
                                </div>
                            </div>
                            <div class="pacman-alert" *ngIf="isAssetGroupDeletionSuccess">
                                <div class="sa-icon sa-success animate">
                                    <span class="sa-line sa-tip animateSuccessTip"></span>
                                    <span class="sa-line sa-long animateSuccessLong"></span>
                                    <div class="sa-placeholder"></div>
                                    <div class="sa-fix"></div>
                                </div>
    
                                <div class="pacman-alert-message">
                                    Asset Group <span class="highlight-pink"><b class="asset-group-deletion">{{groupName}}</b></span> successfully deleted !!!
                                </div>
                                <div class="pacman-alert-button">
                                    <a class="btn btn-border">
                                    <button class="submit-btn uppercase" type="button" (click)="navigateBack()"><i class="fa fa-remove"></i> CLOSE</button></a>
                                </div>
                            </div>
                            <div class="pacman-progress" *ngIf="assetGroupLoader">
                                <div class="title">
                                    Please Wait...
                                </div>
                                <div class="pacman-alert-message">
                                    Deleting Asset Group <span class="highlight-pink">{{groupName}}</span> is in progress...
                                </div>
                            </div>
                        </div>
                    </div><!--  Container ends here -->
                </div>
            </div>
        </div>
    </div>
</section>