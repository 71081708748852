import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../core/services/menu.service';

import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { AssetGroupObservableService } from '../core/services/asset-group-observable.service';
import { Subscription } from 'rxjs/Subscription';
import { LoggerService } from '../shared/services/logger.service';
import { DataCacheService } from '../core/services/data-cache.service';
import { DomainTypeObservableService } from '../core/services/domain-type-observable.service';
import { WorkflowService } from '../core/services/workflow.service';
import { environment } from '../../environments/environment';
import { UpdateRecentAGService } from './common/services/update-recent-ag.service';
import { RecentlyViewedObservableService } from '../core/services/recently-viewed-observable.service';
import { BreadcrumbService } from '../core/services/breadcrumb.service';
import { CONFIGURATIONS } from '../../config/configurations'
import { TENANT_ID } from '../shared/constants/tenant';

@Component({
  selector: 'app-post-login-app',
  templateUrl: './post-login-app.component.html',
  styleUrls: ['./post-login-app.component.css'],
  providers: [UpdateRecentAGService]
})
export class PostLoginAppComponent implements OnInit, OnDestroy {

  navigationDetails: any;
  domainList: string='';
  queryParameters: any = {};
  private agAndDomainKey: string ='';
  showPacLoader: any = [];
  showClass: boolean = false;
  provider:any = [];
  currentAg:any;



  private activatedRouteSubscription: Subscription |any;
  private previousRouteSequence :any;
  public theme :any;
  private pageReloadInterval :any; // Default time is 30 minutes in miliseconds
  private reloadTimeout :any;
  isOffline = false;
  updateRecentAGSubscription: Subscription |any;
  recentTiles:any = [];

  config :any;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recentAssetsObservableService: RecentlyViewedObservableService,
    private assetGroupObservableService: AssetGroupObservableService,
    private dataStore: DataCacheService,
    private logger: LoggerService,
    private domainTypeObservableService: DomainTypeObservableService,
    private menuService: MenuService,
    private dataCacheService: DataCacheService,
    private updateRecentAGService: UpdateRecentAGService,
    private breadCrumbService: BreadcrumbService
  ) {

    if (this.pageReloadInterval) {
      this.reloadTimeout = this.setReloadTimeOut(this.pageReloadInterval);
    }
    this.config = CONFIGURATIONS;
    this.getRouteQueryParameters();

  // get the dropdown list of availabe domain list
   this.assetGroupObservableService
      .getAssetGroup()
      .subscribe((assetGroupName) => {
        if (assetGroupName) {
          this.currentAg = assetGroupName;
          this.updateRecentAssetGroup(this.currentAg);
        }
      });
  }

  ngOnInit() {
      this.agAndDomainKey = '';
  }



  setReloadTimeOut(timeoutInterval :any) {
    this.logger.log('info', 'Setting the page reload interval to: ' + timeoutInterval);
    const reloadTimeout = setTimeout(function(){
      window.location.reload();
    }, timeoutInterval);

    return reloadTimeout;
  }

  getState(outlet :any) {
    return outlet.activatedRouteData.sequence;
  }

  updateAssetGroup(assetGroupName :any) {
    if (assetGroupName) {
      this.assetGroupObservableService.updateAssetGroup(assetGroupName);
    } else {
      debugger
      this.router.navigate(['/compliance/compliance/compliance-dashboard', {outlets: { modal: ['change-default-asset-group'] } }]);
    }
  }

  updateDomainName(domainName :any) {
    const currentAssetGroup = this.dataStore.getCurrentSelectedAssetGroup();
    if (domainName) {
      this.domainTypeObservableService.updateDomainType(domainName, currentAssetGroup);
    }
  }

  getRouteQueryParameters(): any {
    // @ts-ignore
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params:any) => {
        if (params['ag'] || params['domain']) {

          const newKey = params['ag'] + params['domain'];

          if (newKey === this.agAndDomainKey) {
            return false;
          }

          this.queryParameters = params;
          this.agAndDomainKey = newKey;

          this.updateAssetGroup(this.queryParameters['ag']);
          this.updateDomainName(this.queryParameters['domain']);
        }
        // /* User will enter it in minutes */
        // if (params['reload']) {
        //   this.pageReloadInterval = params['reload'] * 60000;
        //   if (this.reloadTimeout) {
        //     clearTimeout(this.reloadTimeout);
        //     this.reloadTimeout = this.setReloadTimeOut(this.pageReloadInterval);
        //   }
        // }
      }
    );
  }



  onDeactivate(event :any) {
    try {
      event.activatedRoute.data.subscribe((data:any) => {
        if ( data) {
          this.previousRouteSequence = data.sequence;
        }
      });

    } catch (e) {

    }
  }


  // get the dropdown list of availabe domain list
  updateRecentAssetGroup(groupName :any) {
    const updateRecentAGUrl = environment.updateRecentAG.url;
    const updateRecentAGMethod = environment.updateRecentAG.method;
    const userId = this.dataCacheService.getUserDetailsValue().getUserId();
    const queryParams = {
      'ag': groupName,
      'userId': userId,
      'tenantId': TENANT_ID.tenantId
    };
    if (queryParams['ag'] !== undefined) {
     this.updateRecentAGSubscription = this.updateRecentAGService.updateRecentlyViewedAG(queryParams, updateRecentAGUrl, updateRecentAGMethod).subscribe(
       response => {
         this.recentTiles = response.data.response[0].recentlyViewedAg;
         /* Store the recently viewed asset list in stringify format */
         this.dataCacheService.setRecentlyViewedAssetGroups(JSON.stringify(this.recentTiles));
         const currentAGDetails = this.recentTiles.filter((element:any) => element.ag === groupName);
         this.provider = this.fetchprovider(currentAGDetails);
         this.recentAssetsObservableService.updateRecentAssets(this.recentTiles);
      },
      error => {

      });
    }
  }


  fetchprovider(assetGroupObject:any) {
    const provider :any = [];
    if (assetGroupObject.length && assetGroupObject[0].providers) {
      assetGroupObject[0].providers.forEach((element:any ) => {
        provider.push(element.provider);
      });
    }
    return provider;
}


  ngOnDestroy() {
    try {
      this.activatedRouteSubscription.unsubscribe();
    } catch (error) {
      this.logger.log('error', error);
    }
  }
  toggleMenu() {
    this.menuService.changeMessage(this.showClass);
  }
  
}
