import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from './../../../../../environments/environment';

import { ActivatedRoute} from "@angular/router";
import {  Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { UtilsService } from '../../../../shared/services/utils.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { ErrorHandlingService } from '../../../../shared/services/error-handling.service';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { WorkflowService } from '../../../../core/services/workflow.service';
import { RouterUtilityService } from '../../../../shared/services/router-utility.service';
import { AdminService } from '../../../services/all-admin.service';
import { UploadFileService } from '../../../services/upload-file-service';
import { CommonResponseService } from '../../../../shared/services/common-response.service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-servicenow',
  templateUrl: './servicenow.component.html',
  styleUrls: ['./servicenow.component.css'],
  providers: [
    LoggerService,
    ErrorHandlingService,
    UploadFileService,
    AdminService,
    CommonResponseService
  ]
})
export class ServicenowComponent implements OnInit, OnDestroy {
  pageTitle = '';
  breadcrumbArray = ['Admin', 'Servicenow Policy Mapping'];
  breadcrumbLinks = ['policies', 'roles'];
  breadcrumbPresent = ['Servicenow Policy Mapping'];
  outerArr = [];
  filters = [];
  
  
  public queryValue: any = '';
  public filteredList: any = [];
  public emailArray: any = [];
  public users: any;
  public idDetailsName: any = [];
  private getUserSubscription: Subscription | undefined;
  invalid = true;
  arrowkeyLocation = 0;

  rulePolicyLoader = false;
  rulePolicyLoaderFailure = false;
  ruleDisplayName = '';
  contentHidden = true;
  ruleIds = [];
  mappedRuleIds = [];

  roles = {
    roleName: '',
    description: '',
    writePermission: false
  };

  isCreate = false;
  successTitle: any = '';
  failedTitle: any = '';
  successSubTitle: any = '';
  availChoosedItems: any = {};
  availChoosedSelectedItems: any = {};
  availChoosedItemsCount = 0;
  selectedRoleName: any = '';

  selectChoosedItems: any = {};
  selectChoosedSelectedItems: any = {};
  selectChoosedItemsCount = 0;

  availableItems: any = [];
  selectedItems: any = [];

  availableItemsBackUp = [];
  selectedItemsBackUp = [];

  availableItemsCopy: any = [];
  selectedItemsCopy: any = [];

  searchSelectedUsersTerms: any = '';
  searchAvailableUsersTerms: any = '';
  isRoleCreationUpdationFailed = false;
  isRoleCreationUpdationSuccess = false;
  highlightName = '';
  allRDetails: any = [];
  allCategoryDetails: any = [];
  allAllocatedUsers: any = [];
  loadingContent = '';
  roleLoader = false;

  roleId = '';

  paginatorSize = 25;
  isLastPage: any;
  isFirstPage: any;
  totalPages: any;
  pageNumber = 0;
  showLoader = true;
  errorMessage: any;

  hideContent = false;

  filterText = {};
  errorValue = 0;

  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  urlToRedirect: any = '';
  mandatory: any;

  public labels: any;
  private previousUrl = '';
  private pageLevel = 0;
  public backButtonRequired: any;
  private routeSubscription: Subscription | undefined;
  private getKeywords: Subscription | undefined;
  private previousUrlSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private workflowService: WorkflowService,
    private routerUtilityService: RouterUtilityService,
    private adminService: AdminService,
    private commonResponseService: CommonResponseService,
    private breadCurmbService: BreadcrumbService
  ) {

    this.routerParam();
    this.updateComponent();
  }

  ngOnInit() {
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    this.urlToRedirect = this.router.routerState.snapshot.url;
	console.log("this.urlToRedirect"+this.urlToRedirect);
    this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(
      this.pageLevel
    );
  }

  nextPage() {
    try {
      if (!this.isLastPage) {
        this.pageNumber++;
        this.showLoader = true;
        // this.getPolicyDetails();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  prevPage() {
    try {
      if (!this.isFirstPage) {
        this.pageNumber--;
        this.showLoader = true;
        // this.getPolicyDetails();
      }

    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }


  onClickAvailableItem(index: any, availableItem: any, key: any) {
    if (this.availChoosedItems.hasOwnProperty(index)) {
      this.availChoosedItems[index] = !this.availChoosedItems[index];
      if (this.availChoosedItems[index]) {
        this.availChoosedSelectedItems[key] = availableItem;
      } else {
        delete this.availChoosedSelectedItems[key];
      }

    } else {
      this.availChoosedItems[index] = true;
      this.availChoosedSelectedItems[key] = availableItem;
    }
    this.availChoosedItemsCount = Object.keys(this.availChoosedSelectedItems).length;
  }

  filter() {
    try {
      if (this.queryValue !== '') {
        var queryValue=this.queryValue.toLowerCase();
        this.filteredList = this.idDetailsName.filter(
          function(el: any) {
            return el.toLowerCase().indexOf(queryValue) > -1;
          }.bind(this)
        );
      } else {
        this.filteredList = [];
      }
    } catch (e) {
      this.logger.log('error', e);
    }
  }

  removeData(index: any): any {
    try {
      this.emailArray.splice(index, 1);
      if (this.emailArray.length < 1) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    } catch (e) {
      this.logger.log('error', e);
    }
  }

  select(item: any) {
    try {
      this.queryValue = item;
      this.filteredList = [];
      this.emailArray.push(item);
      this.queryValue = '';
      if (this.emailArray.length < 1) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    } catch (e) {
      this.logger.log('error', e);
    }
  }

  keyDown(event: KeyboardEvent) {
    try {
      switch (event.keyCode) {
        case 38: // this is the ascii of arrow up
          this.arrowkeyLocation--;
          break;
        case 40: // this is the ascii of arrow down
          this.arrowkeyLocation++;
          break;
        case 13: // this is the ascii of enter
          this.queryValue = this.filteredList[this.arrowkeyLocation];
          this.filteredList = [];
          this.emailArray.push(this.queryValue);
          this.queryValue = '';
          if (this.emailArray.length < 1) {
            this.invalid = false;
          } else {
            this.invalid = true;
          }
      }
    } catch (e) {
      this.logger.log('error', e);
    }
  }

  keyEvent(event: KeyboardEvent, item: any) {
    try {
      switch (event.keyCode) {
        case 13: // this is the ascii of enter
          this.queryValue = item;
          this.filteredList = [];
          this.emailArray.push(item);
          this.queryValue = '';
          if (this.emailArray.length < 1) {
            this.invalid = false;
          } else {
            this.invalid = true;
          }
      }
    } catch (e) {
      this.logger.log('error', e);
    }
  }

  onClickSelectedItem(index: any, selectedItem: any, key: any) {
    if (this.selectChoosedItems.hasOwnProperty(index)) {
      this.selectChoosedItems[index] = !this.selectChoosedItems[index];
      if (this.selectChoosedItems[index]) {
        this.selectChoosedSelectedItems[key] = selectedItem;
      } else {
        delete this.selectChoosedSelectedItems[key];
      }
    } else {
      this.selectChoosedItems[index] = true;
      this.selectChoosedSelectedItems[key] = selectedItem;
    }
    this.selectChoosedItemsCount = Object.keys(this.selectChoosedSelectedItems).length;
  }

  moveAllItemsToLeft() {
    if (this.searchSelectedUsersTerms.length === 0) {
      this.availableItems = _.cloneDeep(this.availableItemsBackUp);
      this.availableItemsCopy = _.cloneDeep(this.availableItemsBackUp);
      this.selectedItems = [];
      this.selectedItemsCopy = [];
      this.selectChoosedItems = {};
      this.selectChoosedSelectedItems = {};
      this.selectChoosedItemsCount = 0;
      this.searchAvailableUsers();
      this.searchSelectedUsers();


    } else {
      this.selectChoosedSelectedItems = {};
      this.selectedItems.forEach((element: any) => {

        this.selectChoosedSelectedItems[element.user] = element;
      });
      this.moveItemToLeft();
    }
  }

  moveAllItemsToRight() {
    if (this.searchAvailableUsersTerms.length === 0) {
      this.selectedItems = _.cloneDeep(this.availableItemsBackUp);
      this.selectedItemsCopy = _.cloneDeep(this.availableItemsBackUp);
      this.availableItemsCopy = [];
      this.availableItems = [];
      this.availChoosedItems = {};
      this.availChoosedSelectedItems = {};
      this.availChoosedItemsCount = 0;
      this.searchAvailableUsers();
      this.searchSelectedUsers();
    } else {
      this.availChoosedSelectedItems = {};
      this.availableItems.forEach((element: any) => {
        this.availChoosedSelectedItems[element.user] = element;
      });
      this.moveItemToRight();
    }
  }

  moveItemToRight() {

    const selectedItemsCopy: any = this.selectedItemsCopy;
    const availableItemsCopy: any = this.availableItemsCopy;
    for (const choosedSelectedKey in this.availChoosedSelectedItems) {
      if (this.availChoosedSelectedItems.hasOwnProperty(choosedSelectedKey)) {
        selectedItemsCopy.push(this.availChoosedSelectedItems[choosedSelectedKey]);
        const filterIndex = availableItemsCopy.indexOf(this.availChoosedSelectedItems[choosedSelectedKey]);
        availableItemsCopy.splice(filterIndex, 1);
      }
    }

    this.availableItems = availableItemsCopy;
    if (this.searchAvailableUsersTerms.length !== 0) {
      this.searchAvailableUsers();
    }

    this.selectedItems = selectedItemsCopy;
    if (this.searchSelectedUsersTerms.length !== 0) {
      this.searchSelectedUsers();
    }

    this.availChoosedItems = {};
    this.availChoosedSelectedItems = {};
    this.availChoosedItemsCount = 0;
  }

  moveItemToLeft() {
    const selectedItemsCopy: any = this.selectedItemsCopy;
    const availableItemsCopy: any = this.availableItemsCopy;
    for (const choosedSelectedKey in this.selectChoosedSelectedItems) {
      if (this.selectChoosedSelectedItems.hasOwnProperty(choosedSelectedKey)) {
        availableItemsCopy.push(this.selectChoosedSelectedItems[choosedSelectedKey]);
        const filterIndex = selectedItemsCopy.indexOf(this.selectChoosedSelectedItems[choosedSelectedKey]);
        selectedItemsCopy.splice(filterIndex, 1);
      }
    }

    this.availableItems = availableItemsCopy;
    if (this.searchAvailableUsersTerms.length !== 0) {
      this.searchAvailableUsers();
    }

    this.selectedItems = selectedItemsCopy;
    if (this.searchSelectedUsersTerms.length !== 0) {
      this.searchSelectedUsers();
    }

    this.selectChoosedItems = {};
    this.selectChoosedSelectedItems = {};
    this.selectChoosedItemsCount = 0;
  }


  searchAvailableUsers() {
    const term = this.searchAvailableUsersTerms;
    this.availableItems = this.availableItemsCopy.filter(function (tag: any) {
      return tag.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    });
  }

  searchSelectedUsers() {
    const term = this.searchSelectedUsersTerms;
    this.selectedItems = this.selectedItemsCopy.filter(function (tag: any) {
      return tag.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    });
  }

  

  closeErrorMessage() {
    this.hideContent = false;
    this.isRoleCreationUpdationFailed = false;
    this.isRoleCreationUpdationSuccess = false;
  }

  getData() {
    // this.getAllPolicyIds();
  }

  /*
    * This function gets the urlparameter and queryObj
    *based on that different apis are being hit with different queryparams
    */
  routerParam() {
    try {
      // this.filterText saves the queryparam
      const currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      console.log("currentQueryParams"+JSON.stringify(currentQueryParams));
      
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;
        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        this.roleId = '1';/*this.queryParamsWithoutFilter.roleId;*/
        const selectedRoleName = 'Admin';/*this.queryParamsWithoutFilter.roleName;*/
        delete this.queryParamsWithoutFilter['filter'];
        if (this.roleId) {
          this.pageTitle = 'Servicenow Policy Mapping';
          // this.breadcrumbPresent = 'Servicenow Policy Mapping';
          this.isCreate = false;
          this.highlightName = selectedRoleName;
          this.getMappedRulesDetails();
        } else {
          this.pageTitle = 'Create Servicenow Mapping';
          // this.breadcrumbPresent = 'Servicenow Policy Mapping';
          this.isCreate = true;
        }

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        // check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  
  /**
   * This function get calls the keyword service before initializing
   * the filter array ,so that filter keynames are changed
   */

  updateComponent() {
    this.outerArr = [];
    this.showLoader = true;
    this.errorValue = 0;
   // this.getUsers();
    this.invalid = true;
  }

  navigateBack() {
    try {
      if(Object.keys(this.selectChoosedItems).length>0){
        window.location.reload();
      }else{
        // this.router.navigate(["../../compliance/compliance-dashboard"], {
          this.router.navigate(["/compliance/admin/admin-servicenow-policy-mapping"],{
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: {
          }
        });
      }
       this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }
      if (this.previousUrlSubscription) {
        this.previousUrlSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }

  getAllRuleIds() {
	const queryparams = {};
    this.rulePolicyLoader = true;
    this.hideContent = true;
    this.roleLoader = true;
	
    this.rulePolicyLoaderFailure = false;
    const url = environment.getAllRuleIds.url;
    const method = environment.getAllRuleIds.method;
    this.adminService.executeHttpAction(url, method, {}, {}).subscribe(response => {
		response[0].forEach((element: any)=>{
			 if (this.allAllocatedUsers.indexOf(element) == -1) {
				 this.idDetailsName.push(element);		//available values
			 
			 } else {
			  this.emailArray.push(element);	// selected values
		   }
		})
  /*  for (let i = 0; i < this.ruleIds.length; i++) {	   
       if (this.allAllocatedUsers.indexOf(this.ruleIds[i]) == -1) {
          this.idDetailsName.push(this.ruleIds[i]);		//available values
       
       } else {
          this.emailArray.push(this.ruleIds[i]);	// selected values
       }			
      }
	 */
	  const availableItems = _.cloneDeep(this.idDetailsName);
      const selectedItems = _.cloneDeep(this.emailArray);

      this.availableItems = _.cloneDeep(availableItems);
      this.selectedItems = _.cloneDeep(selectedItems);
      this.availableItemsBackUp = _.cloneDeep(availableItems);
      this.selectedItemsBackUp = _.cloneDeep(selectedItems);
      this.availableItemsCopy = _.cloneDeep(availableItems);
      this.selectedItemsCopy = _.cloneDeep(selectedItems);
      this.searchAvailableUsers();
      this.searchSelectedUsers();
	  this.hideContent = false;
	  this.roleLoader = false;


    },
      error => {
        this.contentHidden = true;
        this.rulePolicyLoader = false;
        this.rulePolicyLoaderFailure = true;
        this.ruleIds = [];
        this.errorMessage = 'apiResponseError';
        this.showLoader = false;
		this.hideContent = false;
	  this.roleLoader = false;
      });
  }
  getMappedRulesDetails() {
    this.hideContent = true;
    this.roleLoader = true;
    this.loadingContent = 'loading';
    this.isRoleCreationUpdationFailed = false;
    this.isRoleCreationUpdationSuccess = false;
    const url = environment.getMappedServicenowRulesIds.url;
    const method = environment.getMappedServicenowRulesIds.method;
    this.errorValue = 1;

    this.adminService.executeHttpAction(url, method, {}, {}).subscribe(userRoleReponse => { 
      console.log("userRoleReponse 1", userRoleReponse)
		console.log("userRoleReponse"+JSON.stringify(userRoleReponse));
    let array:any=[];
    array.push(userRoleReponse[0]);
    console.log("array 1", array)
	  if (!this.isCreate) {
		  array.forEach((element: any)=>{	
        console.log("element 1", element)		
				this.allAllocatedUsers.push(element);		   
		})
		
       // const userNames = //this.responseHardcodedValue;// api call to be added here.
		 
        //var array = userNames.split(',');
        //console.log("userNames_new function"+userNames);
        //this.allAllocatedUsers = this.mappedRuleIds;// _.uniq(userNames);
		console.log("allAllocatedUsers"+this.allAllocatedUsers);
		this.getAllRuleIds();
		console.log("After get all ruleIDs");

		
       // this.getUsers();
       
      /*  this.highlightName = userRoleReponse[0].roleName;
        this.roles.roleName = userRoleReponse[0].roleName;
        this.roles.description = userRoleReponse[0].roleDesc;
        this.roles.writePermission = userRoleReponse[0].writePermission;
        */
		 this.hideContent = false;
    this.roleLoader = false;
      }
	  
    },
      error => {
        this.errorValue = -1;
        this.outerArr = [];
        this.errorMessage = 'apiResponseError';
        this.showLoader = false;
        this.failedTitle = 'Loading Failed';
        this.loadingContent = 'Loading';
        this.highlightName = 'Servicenow Policy Mapping';
        this.isRoleCreationUpdationFailed = true;
        this.roleLoader = false;
		this.hideContent = false;
    
      });
  }
  
  configureServicenow() {
    this.loadingContent = 'configuring';
    this.selectedRoleName = this.highlightName;
    this.hideContent = true;
    this.roleLoader = true;
    this.isRoleCreationUpdationFailed = false;
    this.isRoleCreationUpdationSuccess = false;
    let servicenowConfigRequest: any = [];  
   // servicenowConfigRequest['roleId'] = this.roleId;
   // const userId = [];
   console.log("selected items are"+this.selectedItems);
    //servicenowConfigRequest['servicenowMapping'] = this.selectedItems;
	this.selectedItems.forEach((element: any)=>{
		servicenowConfigRequest.push(
		{
			"ruleId" : element,
			"ruleName" : element
		}
		);
	})
	console.log("servicenowConfigRequest" +JSON.stringify(servicenowConfigRequest));
    const url = environment.postMappedServicenowRulesIds.url;
    const method = environment.postMappedServicenowRulesIds.method;
    this.adminService.executeHttpAction(url, method, servicenowConfigRequest, {}).subscribe(reponse => {
      this.successTitle = 'Servicenow Policies Configured !!!';
      this.isRoleCreationUpdationSuccess = true;
	  console.log("isRoleCreationUpdationSuccess"+this.isRoleCreationUpdationSuccess);
      this.roleLoader = false;
      this.roles = {
        roleName: '',
        description: '',
        writePermission: false
      };
    },
      error => {
        this.failedTitle = 'Servicenow mapping Failed !!!';
        this.roleLoader = false;
        this.isRoleCreationUpdationFailed = true;
      });
  }
}
