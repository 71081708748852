import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pageloader',
  templateUrl: './pageloader.component.html',
  styleUrls: ['./pageloader.component.css'],
})
export class PageLoaderComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit() {
    // Redirect to the desired route after a certain delay (adjust as needed)
    setTimeout(() => {
      this.router.navigate(['compliance/compliance/compliance-dashboard']);
    }, 2000); // Adjust the delay in milliseconds
  }

  dataLoaded: boolean = false;

  back(): void {
    this.router.navigate(['compliance/compliance/compliance-dashboard']);
  }
}