import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import {Subscription} from 'rxjs/Subscription';

import * as _ from 'lodash';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { DomainTypeObservableService } from 'src/app/core/services/domain-type-observable.service';
import { UtilsService } from '../../services/utils.service';
import { LoggerService } from '../../services/logger.service';
import { FilterManagementService } from '../../services/filter-management.service';
import { TENANT_ID } from '../../constants/tenant';

@Component({
  selector: 'app-generic-page-filter',
  templateUrl: './generic-page-filter.component.html',
  styleUrls: ['./generic-page-filter.component.css']
})
export class GenericPageFilterComponent implements OnInit, OnDestroy, OnChanges {
  filterSubscription: Subscription | undefined;
  assetGroupSubscription: Subscription | undefined;
  domainSubscription: Subscription | undefined;

  @Input() filterId?: any;
  @Input() filterArray: any = [];
  @Input() selectOnSingleValue?: any; // check to make filter as selected on single value for second dropdown
  @Input() mandatoryFilter: any; // check for any mandatory filter
  @Output() onFilterValueChange = new EventEmitter();
  @Input() clearSelectedFilterValue? = false;
  agAndDomain: any = {};
  disableFilterTags = true;
  dataAvailable = false;
  filterValues: any = {
    filterTypeLabels: [], // contains all filter labels available to show user
    filterTypeOptions: [], // contians filter id, url to get values corresponsing to that filter
    filterValuesLabels: [], // contains values of a particular filter which is selected
    filterValuesOptions: [], // contains filter vaules id and name
    currentSelectedFilterType: {},
    currentSelectedFilterValue: {},
  };

  constructor(private assetGroupObservableService: AssetGroupObservableService,
              private domainObservableService: DomainTypeObservableService,
              private utils: UtilsService,
              private logger: LoggerService,
              private filterManagementService: FilterManagementService) {

    this.subscribeToAssetGroup();
    this.subscribeToDomain();

  }

  ngOnInit() {
    this.reset();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    // clear all value by default - currenlty used in recommendations to reset on ag change
    const toClearValueChange = changes['clearSelectedFilterValue'];
    if (toClearValueChange && !toClearValueChange.firstChange) {
      const cur  = JSON.stringify(toClearValueChange.currentValue);
      const prev = JSON.stringify(toClearValueChange.previousValue);
        if (cur !== prev) {
          this.disableFilterTags = false;
          this.dataAvailable = false;
        }
    }
  }

  subscribeToAssetGroup() {
    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assetGroup => {
      if (assetGroup) {
        this.agAndDomain['ag'] = assetGroup;
      }
    });
  }

  subscribeToDomain() {
    this.domainSubscription = this.domainObservableService.getDomainType().subscribe(domain => {
      if (domain) {
        this.agAndDomain['domain'] = domain;
      }

      this.reset();
      this.init();
    });
  }

  reset() {
    /* Reset the values */
  }

  init() {
    this.getFilters();
  }

  getFilters() {
    try {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      if (this.filterId) {
        this.filterSubscription = this.filterManagementService.getApplicableFilters(this.filterId)
            .subscribe((response: any) => {
              this.dataAvailable = true;
              const responseData = this.removeMandatoryFilter(response[0].response);
              // Filter out the "Policy" option
              const filteredData = responseData.filter((option:any) => option.optionName !== 'Policy' && option.optionName !== 'AccountName' && option.optionName !== 'Region' && option.optionName !== 'Rule');
              // Update the filterValues
              this.filterValues['filterTypeLabels'] = _.map(filteredData, 'optionName');
              this.filterValues['filterTypeOptions'] = responseData;
              if (this.filterValues['filterTypeLabels'].length === 1) {
                this.changeFilterType({
                  'value': this.filterValues['filterTypeLabels'][0]
                });
              }
            },
              (error: any) => {
              this.dataAvailable = true;
            });
      }
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  changeFilterType(value: any) {
    try {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      this.dataAvailable = false;
      this.filterValues.filterValuesOptions = [];
      this.filterValues.filterValuesLabels = [];
      this.filterValues.currentSelectedFilterType = {};
      this.disableFilterTags = false;
      this.filterValues.currentSelectedFilterType = _.find(this.filterValues.filterTypeOptions, {
        optionName: value.value
      });

      const queryParams = {
        ag: this.agAndDomain['ag'],
        domain: this.agAndDomain['domain'],
        tenantId: TENANT_ID.tenantId
      };

      this.filterSubscription = this.filterManagementService.getValuesForFilterType(this.filterValues.currentSelectedFilterType, queryParams).subscribe((response: any) => {
        this.dataAvailable = true;
        if(this.filterValues.currentSelectedFilterType.optionName=="Account Id"){
          this.filterValues.filterValuesLabels = [...new Set(response[0].response.map((item:any) => item.id))];
        }else{
          this.filterValues.filterValuesLabels = [...new Set(response[0].response.map((item:any) => item.name))];
          // this.filterValues.filterValuesLabels = _.map(response[0].response, 'name');
        }
        this.filterValues.filterValuesOptions = response[0].response;
        if (this.filterValues.filterValuesOptions.length === 1 && this.selectOnSingleValue) {
          this.changeFilterValue(this.filterValues.filterValuesOptions[0], true);
        }
      }, (error: any) => {
        this.dataAvailable = true;
      });
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  changeFilterValue(value: any, mandatory?: any) {
    try {
      if (this.filterValues.currentSelectedFilterType) {
        const filterTag = mandatory ? value : _.find(this.filterValues.filterValuesOptions, { name: value.value });
        this.utils.addOrReplaceElement(
            this.filterArray,
            {
              key: this.filterValues.currentSelectedFilterType['optionName'],
              value: filterTag.id,
              filterkey: this.filterValues.currentSelectedFilterType['optionValue'].trim(),
              compareKey : this.filterValues.currentSelectedFilterType['optionValue'].toLowerCase().trim()
            },
            (el: any) => {
              return el.compareKey === this.filterValues.currentSelectedFilterType['optionValue'].toLowerCase().trim();
            }
        );
      }
      /* Emit value to parent to notify change */
      if (mandatory) {
        // provide mandatory key when check is passed to select filter on single value
        this.filterArray['mandatory'] = this.filterValues['filterTypeOptions'][0].optionValue;
      }
      this.onFilterValueChange.emit(this.filterArray);
      this.utils.clickClearDropdown();
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  removeMandatoryFilter(data: any) {
    // remove mandatory filter from filter dropdown
    let mandatory = this.mandatoryFilter;
    if (mandatory && mandatory.includes('|')) {
      mandatory = mandatory.split('|');
    }
    if (mandatory) {
      const filteredOptionValue = _.map(data, 'optionValue');
      filteredOptionValue.forEach(value => {
        if (mandatory.includes(value.trim())) {
          data = _.reject(data, {'optionValue': value});
        }
      });
    }
    return data;
  }

  ngOnDestroy() {
    try {
      if (this.assetGroupSubscription) {
        this.assetGroupSubscription.unsubscribe();
      }
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      if (this.domainSubscription) {
        this.domainSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', 'js error - ' + error);
    }
  }
}
