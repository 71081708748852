<!-- <app-policy-violation-table [cbModel]='cbModel' (cbClicked)='checkBoxClicked($event)' [checkBox]='adminAccess'
    [searchTextValues]='searchTxt' [showGenericMessage]='showGenericMessage' [errorValue]='errorValue'
    [parentName]="'policyDetailsTableMessage'" [showingArr]='showingArr' [outerArr]='outerArr' [searchableHeader]='true'
    [allColumns]='allColumns' [dataHead]="'Policy Violations'" (selectedRow)='goToDetails($event)'
    [columnWhiteList]="['Issue ID', 'Policy Name','Resource ID', 'Resource Type', 'Status', 'Severity', 'Rule Category', 'Account Name', 'Account ID', 'AWS Region', 'Created On', 'Modified On', 'Target Type', 'Description', 'Application', 'Environment']"
    [firstPaginator]='firstPaginator' [lastPaginator]='lastPaginator' [totalRows]='totalRows'
    [currentPointer]='currentPointer' (searchRowTxt)='searchCalled($event)' (previousPageCalled)='prevPg()'
    (nextPageCalled)='nextPg()' (searchTriggerred)='callNewSearch()' [popRows]='popRows' [checkedList]='cbArr'
    (rowClickText)='handlePopClick($event)'>
</app-policy-violation-table> -->

<section>
    <div class="card tab-box">
     <div class="row">
         <div class="col-md-6 header-div">
             <h6 class="card-title">Policy Violations</h6>
         </div>
         <div class="col-md-6 searchable-div">
             <div class="filter-type-div">
                 <app-searchable-dropdown *ngIf='filterTypeLabels.length' [firstDD]='true'
                     (selection)='changeFilterType($event)' [placeholder]="'Select filter type'" [items]='filterTypeLabels'>
                 </app-searchable-dropdown>
             </div>
             <div class="filter-tag-div">
                 <app-searchable-dropdown (selection)='changeFilterTags($event)' [placeholder]="'Search filter tags'"
                     [items]='filterTagLabels'></app-searchable-dropdown>
             </div>
         </div>
         <div class='floating-widgets-filter-wrapper' *ngIf='filters.length'>
             <app-filtered-selector (deleteAllFilters)="deleteFilters($event)" (deleteFilter)="deleteFilters($event)"
                 [(filteredArray)]="filters"></app-filtered-selector>
         </div>
     </div>
     <app-policy-violation-table [cbModel]='cbModel' (cbClicked)='checkBoxClicked($event)' [checkBox]='adminAccess'
     [searchTextValues]='searchTxt' [showGenericMessage]='showGenericMessage' [errorValue]='errorValue'
     [parentName]="'policyDetailsTableMessage'" [showingArr]='showingArr' [outerArr]='outerArr' [searchableHeader]='true'
     [allColumns]='allColumns' [dataHead]="'Policy Violations'" (selectedRow)='goToDetails($event)'
     [columnWhiteList]="['Issue ID', 'Policy Name','Resource ID', 'Resource Type', 'Status', 'Severity', 'Rule Category', 'Account Name', 'Account ID', 'AWS Region', 'Created On', 'Modified On', 'Target Type', 'Description', 'Bcbsa:Application Name', 'Bcbsa:Environment Type']"
     [firstPaginator]='firstPaginator' [lastPaginator]='lastPaginator' [totalRows]='totalRows'
     [currentPointer]='currentPointer' (searchRowTxt)='searchCalled($event)' (previousPageCalled)='prevPg()'
     (nextPageCalled)='nextPg()' (searchTriggerred)='callNewSearch()' [popRows]='popRows' [checkedList]='cbArr'
     (rowClickText)='handlePopClick($event)'>
 </app-policy-violation-table>
 </div>
 </section>