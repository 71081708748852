import {Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent {

    constructor(private router: Router) {
        // this.router.navigate(['compliance/compliance/compliance-loader']);
     }
   
    back(): void {
        this.router.navigate(['compliance/compliance/compliance-dashboard']);
    }

}
