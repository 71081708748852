  <div id='oopss'>
    <div id='error-text'>
        <img src="assets/images/sad404.svg" alt="404">
        <span>PAGE NOT FOUND</span>
        <p class="p-a">
           . The page you were looking for could not be found</p>
        <p class="p-b back-nav" (click)="back()">
            ... Back to Home page
        </p>
       
    </div>
</div>