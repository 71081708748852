<section class="assetgroup">
    <div class="container-fluid py-1">
      <div class="row">
        <div class="col-12">
            <div class="container-fluid mt-2 p-1 policy-violation dataTable-section-height">
              <div class="col-md-6">
                <h6 class="card-title header-tag">{{dataHead}}</h6>
              </div>
              <!-- <div *ngIf="errorValue == 0" class="loader-container">
                <div class="loader">
                  <span></span>
                </div>
              </div> -->
              <article class="error_handling" *ngIf="errorValue == -1 && errorMessage">
                <div class="error_msg">
                  <app-error-message *ngIf="errorValue == -1 && errorMessage"
                    [selectedValue]="errorMessage"></app-error-message>
                </div>
              </article>
              <table datatable [dtOptions]="dtOptions" *ngIf="
                  errorValue > 0 &&
                  loaded &&
                  filteredColumns.length > 0 &&
                  sortArr.length > 0
                " id="policy-violation" class="table nowrap policy-violation-table table-hover" width="100%" #datatable>
                <thead>
                  <tr>
                    <th (click)="
                        sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header)"
                      *ngFor="let header of filteredColumns; let i = index">
                      {{ header }}
                      <span [class.up-arr-shown]="sortArr[i].showUp == true"
                        [class.down-arr-shown]="sortArr[i].showUp == false" class="list-sortable-arrow"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                      let row of currentTableData
                        | searchFilter : searchPassed
                        | orderBy
                          : {
                              property: headerColName,
                              direction: direction,
                              childProperty: 'valText'
                            };
                      let i = index
                    ">
                    <td  [ngClass]="{
                        sorting_1: row[column]?.colName === sortSelectedColomn
                      }" *ngFor="let column of filteredColumns">
                      <a *ngIf='row[column]?.link && row[column]?.text'
                        (click)='currentRowData = row;goToDetails(currentRowData, column);(row[column].text === "Edit" || row[column].text === "Disable") ? $event.stopPropagation() :""'
                        class="table-trucate" data-bs-toggle="tooltip" title="{{ row[column]?.text }}"
                        [ngClass]="{ low: row[column]?.colName == 'Status' }">
                        <span [ngClass]="{
                            'low-dot':
                              row[column]?.text === 'medium' ||
                              row[column]?.text === 'low',
                            'high-dot':
                              row[column]?.text === 'high' ||
                              row[column]?.text === 'critical'
                          }" *ngIf="row[column]?.colName == 'Severity'">
                          <i class="bi bi-circle-fill"></i>
                        </span>
                        {{ row[column]?.text || "No Data" }}
                      </a>
                      <a *ngIf='!row[column]?.link' (click)='tableRowClicked(row, i)' data-bs-toggle="modal" data-bs-target="#modalForm"
                        class="table-trucate"  title="{{ row[column]?.text }}"
                        [ngClass]="{ low: row[column]?.colName == 'Status' }">
                        <span [ngClass]="{
                            'low-dot':
                              row[column]?.text === 'medium' ||
                              row[column]?.text === 'low',
                            'high-dot':
                              row[column]?.text === 'high' ||
                              row[column]?.text === 'critical'
                          }" *ngIf="row[column]?.colName == 'Severity'">
                          <i class="bi bi-circle-fill"></i>
                        </span>
                        {{ row[column]?.text || "No Data" }}
                      </a>
  
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <!-- Table Pagination Strip -->
              <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
                <div class='checked-list' *ngIf='checkBox'>Selected: <span
                    class='checked-list-length'>{{checkedList.length}}</span>
                </div>
                <div class='pagination-strip'>
                  <div class='me-3'> Showing {{firstPaginator}} to <span
                      *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                      entries</span>
                  </div>
                  <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                      class="bi bi-chevron-left"></i>
                  </div>
                  <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                      [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
              <!-- Data Table Ends here -->
            </div>
            <!-- Table Container ends here -->
        </div>
      </div>
    </div>
  </section>
  <!-- Policy Violation Section End -->
  
 