<div
  *ngIf="showLoader"
  class="loader-container multigraph">
  <div class="loader">
    <span></span>
  </div>
</div>
<section>
  <div class="container-fluid py-1 main-div">
    <div class="row">
      <div class="col-md-7 col-7 text-end mt-4 mb-2"></div>
      <div class="col-md-5 col-5 text-end mt-4 mb-2 category">
        <label class="targetlabel">Target Type</label>
        <ngx-select
          class="form-select"
          [items]="taggingSummary"
          [formControl]="selectControl"
          optionValueField="name"
          optionTextField="name"
          (select)="onSelectcategory($event)"
          [autoClearSearch]="false"
          placeholder="Select Target Types"
          required>
        </ngx-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
       
        <app-tagging-summary [pageLevel]="pageLevel"></app-tagging-summary>
      </div>
      <div class="col-md-8">
        <app-total-tag-compliance [pageLevel]="pageLevel"></app-total-tag-compliance>
      </div>
    </div>
  </div>

  <app-tagging-compliance-trend></app-tagging-compliance-trend>

  <app-tagging-across-target-type [pageLevel]="pageLevel"></app-tagging-across-target-type>

  <!-- <app-tagging-instances-table></app-tagging-instances-table> -->

  <app-tagging-assets-trend></app-tagging-assets-trend>
</section>
