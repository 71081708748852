
export const COMMON_PAGES = [
  'compliance-dashboard',
  'policy-details',
  'issue-listing',
  'issue-details',
  'policy-knowledgebase',
  'policy-knowledgebase-details',
  'asset-dashboard',
  'asset-list',
  'tools-landing',
  'omni-search-page',
  'admin-policies',
  'risk-management',
  'create-risk',
  'riskcreate',
  'update-risk',
  'security-assessment',
  'riskoverview'
];

export const DOMAIN_MAPPING = [
  {
    'dashboards': [
      {
        'moduleName': 'compliance',
        'dashboards': [
          {
            'route': 'compliance-dashboard',
            'sequence': 0
          },
          {
            'route': 'issue-listing',
            'sequence': 1
          },
          {
            'route': 'policy-knowledgebase',
            'sequence': 2
          },
          // {
          //   'route': 'recommendations',
          //   'sequence': 3,
          //   'groupBy': 'compliance-dashboard'
          // },
          // {
          //   'route': 'vulnerabilities-compliance',
          //   'sequence': 4,
          //   'groupBy': 'compliance-dashboard'
          // },
        ]
      },
      {
        'moduleName': 'assets',
        'dashboards': [
          {
            'route': 'asset-dashboard',
            'sequence': 0
          },
          {
            'route': 'asset-list',
            'sequence': 1
          }
        ]
      },
      {
        'moduleName': 'tools',
        'dashboards': [
          {
            'route': 'tools-landing',
            'sequence': 0
          }
        ]
      },
      {
        'moduleName': 'omnisearch',
        'dashboards': [
          {
            'route': 'omni-search-page',
            'sequence': 0
          },
          {
            'route': 'omni-search-details',
            'sequence': 1
          }
        ]
      },
      {
        'moduleName': 'admin',
        'dashboards': [
          {
            'route': 'admin-policies',
            'sequence': 0
          },
          {
            'route': 'admin-rules',
            'sequence': 1
          },
          {
            'route': 'admin-job-execution-manager',
            'sequence': 2
          },
          {
            'route': 'admin-domains',
            'sequence': 3
          },
          {
            'route': 'admin-target-types',
            'sequence': 4
          },
          {
            'route': 'admin-asset-groups',
            'sequence': 5
          },
          // {
          //   'route': 'admin-sticky-exceptions',
          //   'sequence': 6
          // },
          // {
          //   'route': 'admin-asset-groups',
          //   'sequence': 5
          // },
          // {
          //   'route': 'admin-sticky-exceptions',
          //   'sequence': 6
          // },
          // {
          //   'route': 'admin-users',
          //   'sequence': 7
          // },
          // {
          //   'route': 'admin-roles',
          //   'sequence': 8
          // },
          // {
          //   'route': 'admin-config-management',
          //   'sequence': 9
          // },
          /*{
              'route': 'account-management',
              'sequence': 8
          },
          {
              'route': 'plugin-management',
              'sequence': 9
          }*/
          {
            'route': 'admin-system-management',
            'sequence': 10
          }
          // {
          //   'route': 'admin-servicenow-policy-mapping',
          //   'sequence': 11
          // }
        ]
      },
      {
        'moduleName': 'grc',
        'dashboards': [
          {
            'route': 'risk-management',
            'sequence': 0
          }
        ]
      },
      {
        'moduleName': 'grc',
        'dashboards': [
          {
            'route': 'create-risk',
            'sequence': 0
          }
        ]
      },
      {
        'moduleName': 'grc',
        'dashboards': [
          {
            'route': 'riskcreate',
            'sequence': 0
          }
        ]
      },
      {
        'moduleName': 'grc',
        'dashboards': [
          {
            'route': 'update-risk',
            'sequence': 0
          }
        ]
      },
      {
        'moduleName': 'grc',
        'dashboards': [
          {
            'route': 'riskoverview',
            'sequence': 0
          }
        ]
      },
      {
        'moduleName': 'grc',
        'dashboards': [
          {
            'route': 'security-assessment',
            'sequence': 0
          }
        ]
      }
    ],
    'domain': ''
  },
  {
    'dashboards': [
      {
        'moduleName': 'compliance',
        'dashboards': [
          {
            'route': 'tagging-compliance',
            'sequence': 2
          }
          // {
          //   'route': 'health-notifications',
          //   'sequence': 3,
          //   'cloudSpecific': true
          // }
        ]
      },
      {
        'moduleName': 'assets',
        'dashboards': []
      }
    ],
    'domain': 'Infra & Platforms'
  },
  {
    'dashboards': [
      {
        'moduleName': 'compliance',
        'dashboards': []
      }
    ],
    'domain': 'SOX'
  },
  {
    'dashboards': [
      {
        'moduleName': 'compliance',
        'dashboards': [
          {
            'route': 'dev-standard-dashboard',
            'sequence': 2
          }
        ]
      }
    ],
    'domain': 'Dev Standards'
  },
  {
    'dashboards': [
      {
        'moduleName': 'compliance',
        'dashboards': [
          {
            'route': 'tagging-compliance',
            'sequence': 2
          },
          {
            'route': 'health-notifications',
            'sequence': 3
          }
        ]
      },
      {
        'moduleName': 'assets',
        'dashboards': []
      }
    ],
    'domain': 'UST'
  }
];