import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Inject,
} from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { AssetGroupObservableService } from '../../../../core/services/asset-group-observable.service';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../shared/services/logger.service';
import { WorkflowService } from '../../../../core/services/workflow.service';
import { SelectComplianceDropdown } from '../../../services/select-compliance-dropdown.service';
import { NgxSelectComponent } from 'ngx-select-ex';
import { FormControl } from '@angular/forms';
import { TENANT_ID } from 'src/app/shared/constants/tenant';
import { environment } from 'src/environments/environment';
import { TaggingComplianceService } from 'src/app/pacman-features/services/tagging-compliance.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { TaggingAcrossTargetTypeComponent } from 'src/app/pacman-features/secondary-components/tagging-across-target-type/tagging-across-target-type.component';
import { TaggingSummaryComponent } from 'src/app/pacman-features/secondary-components/tagging-summary/tagging-summary.component';
import { TotalTagComplianceComponent } from 'src/app/pacman-features/secondary-components/total-tag-compliance/total-tag-compliance.component';
@Component({
  selector: 'app-tagging-compliance',
  templateUrl: './tagging-compliance.component.html',
  styleUrls: ['./tagging-compliance.component.css']
})
export class TaggingComplianceComponent implements OnInit, OnDestroy {
  @ViewChild('targetType') targetTypeSelectComponent: NgxSelectComponent | any;
  @ViewChild('targetTypeRuleSelect') targetTypeRuleSelectComponent: NgxSelectComponent | any;
  @ViewChild(TaggingAcrossTargetTypeComponent) taggingAcrossTargetTypeComponent: TaggingAcrossTargetTypeComponent | any; 
  @ViewChild(TaggingSummaryComponent) taggingSummaryComponent: TaggingSummaryComponent | any; 
  @ViewChild(TotalTagComplianceComponent) totalTagComplianceComponent: TotalTagComplianceComponent | any; 

  breadcrumbArray: any = ['Compliance'];
  breadcrumbLinks: any = ['compliance-dashboard'];
  breadcrumbPresent: any = 'Tagging Compliance';
  awsResourceDetails: any = [];
  awsResourceDetailsname:any = [];
  complianceDropdowns: any = [];
  searchDropdownData: any = {};
  selectedDD = '';
  currentObj: any = {};
  filterArr: any = [];
  subscriptionToAssetGroup: Subscription;
  selectedAssetGroup: string = '';
  selectedComplianceDropdown: any;
  public pageLevel = 0;
  public backButtonRequired: any;
  public selectControl = new FormControl();
  taggingSummaryByTargetType: any;
  errorMessage = '';
  taggingSummary: any = ["All Assets","ec2","lambda","rdsdb","rdscluster"];
  taggingSummaryMaster: any;
  error = false;
  loading = false;
  showloader = false;
  public showLoader = false;
  constructor(
    @Inject(TaggingComplianceService) private taggingComplianceService: TaggingComplianceService,
    // private taggingComplianceService: TaggingComplianceService,
    private breadCurmbService: BreadcrumbService,
    private assetGroupObservableService: AssetGroupObservableService,
    private selectComplianceDropdown: SelectComplianceDropdown,
    private router: Router,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private workflowService: WorkflowService
  ) {
    this.subscriptionToAssetGroup = this.assetGroupObservableService
      .getAssetGroup()
      .subscribe((assetGroupName) => {
        this.backButtonRequired =
          this.workflowService.checkIfFlowExistsCurrently(this.pageLevel);
        this.selectedAssetGroup = assetGroupName;
      });
    this.selectComplianceDropdown
      .getCompliance()
      .subscribe((complianceName) => {
        this.selectedComplianceDropdown = complianceName;
      });
  }

  ngOnInit(): void {
    this.breadCurmbService.changeBreadCrumbData(
      this.breadcrumbArray,
      this.breadcrumbLinks,
      this.breadcrumbPresent,
      false
    );
    this.getData();
    this.showloader = true;
    setTimeout(() => {
      this.selectControl.setValue(["All"]);
    },1000)
  }
  ngOnDestroy(): void {}
  onSelectcategory(onSelectcategory:any){
    this.showloader = true;
    console.log(onSelectcategory)
    console.log(this.taggingSummaryMaster)
    const targettypeselected = this.taggingSummaryMaster.find((item:any) => item.name === onSelectcategory);
    this.showloader = true;
    if (targettypeselected) {
      this.showloader = true;
      this.taggingSummaryComponent.progressDataProcess(targettypeselected);
      this.totalTagComplianceComponent.updateTargetType(onSelectcategory)
    } else {
      this.taggingSummaryComponent.getProgressData();
      this.showloader = true;
      this.totalTagComplianceComponent.updateTargetType(onSelectcategory)
      console.log("Selected target type not found");
    }
    // if (this.taggingAcrossTargetTypeComponent) {
    //         this.taggingAcrossTargetTypeComponent.filteredData(onSelectcategory); 
    //       } else {
    //         console.error('TaggingAcrossTargetTypeComponent not found.');
    //       }
  }

   getData() {
      try {
        const tenantId = TENANT_ID;
        const payload = {
          'targettype':'ec2',
          ag: this.selectedAssetGroup,
          filter: {}
        };
        const url = environment.taggingSummaryByTargetType.url;
        const method = environment.taggingSummaryByTargetType.method;      
        this.taggingComplianceService
          .getTaggingSummaryByTargetType(payload, url, method, tenantId)
          .subscribe(
            response => {
              try {
                if (response.length === 0) {
                  this.errorMessage = 'taggingTargetMessage';
                  this.setError();
                } else {
                  this.taggingSummaryMaster = response;
                }
              } catch (error) {
                this.setError();
                this.errorMessage = this.errorHandling.handleJavascriptError(
                  error
                );
              }
            },
            error => {
              this.setError();
              if (error === 'apiResponseError') {
                this.errorMessage = error;
              }
            }
          );
      } catch (error) {
        this.setError();
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
      }
    }
    
  setError() {
    this.loading = false;
    this.error = true;
  }

 }
