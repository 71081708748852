<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="row">
        <div class="col-md-3 mb-4">
          <h6 class="card-title"></h6>
        </div>
      </div>
    </div>
    <div class="floating-widgets-container">
      <div>
        <div class="widget-wrapper relative">
          <div class="data-table-wrap relative">
            <div class="sub-head">
              <div class="flex flex-between flex-align-center p-25">
                <div class="sub-head-title capitalize flex flex-align-center after-load">
                  <h6 class="card-title">{{pageTitle}}</h6>
                </div>
                <input
                  type="text"
                  class="searchBox"
                  placeholder="Filter Target Types..."
                  [(ngModel)]="searchTerm"
                  (ngModelChange)="searchAttribute()"
                  *ngIf="stepIndex==1">
              </div>
            </div>
            <div class="md-stepper-horizontal orange">
              <div class="md-step active">
                <div class="md-step-circle">
                  <span class="fa fa-arrows"><img src="assets/images/groups.svg"></span>
                </div>
                <div class="md-step-title">Exception Details</div>
                <div class="md-step-bar-left"></div>
                <div
                  class="md-step-bar-right"
                  [ngClass]="{'md-step-active': (stepIndex>0)}"></div>
              </div>
              <div
                class="md-step"
                [ngClass]="{'active': (stepIndex>0)}">
                <div class="md-step-circle">
                  <span class="fa fa-tasks"><img src="assets/images/attribute.svg"></span>
                </div>
                <div class="md-step-title">Target Types</div>
                <div
                  class="md-step-bar-left"
                  [ngClass]="{'md-step-active': (stepIndex>0)}"></div>
                <div
                  class="md-step-bar-right"
                  [ngClass]="{'md-step-active': (stepIndex>1)}"></div>
              </div>
            </div>
            <div [hidden]="hideContent">
              <div class="data-content group-details">
                <div
                  class="pacman-progress"
                  [hidden]="!assetLoader">
                  <!-- <app-pacman-loader></app-pacman-loader> -->
                  <div class="title">Please Wait...</div>
                  <div class="pacman-alert-message">
                    <span class="highlight-pink">{{assetLoaderTitle}}</span>
                    is been loading...
                  </div>
                </div>
                <div
                  class="pacman-progress pacman-alert createsubmit"
                  [hidden]="!assetLoaderFailure">
                  <div
                    class="sa-icon sa-error animateErrorIcon"
                    style="display: block;">
                    <span class="sa-x-mark animateXMark">
													
                      <span class="sa-line sa-left"></span>
											
                      <span class="sa-line sa-right"></span>
											</span>
                  </div>
                  <div class="title">Loading Failed!!!</div>

                  <div class="pacman-alert-message">
                    Failed in loading
                    <span class="highlight-pink small">{{assetLoaderTitle}}</span>
                    ...
                  </div>
                  <a
                    class="btn btn-border me-2 mb-4 cancelbutton"
                    *ngIf="isCreate">
                    <button
                      class="submit-btn"
                      type="button"
                      (click)="closeAssetErrorMessage()">
                      <i class="bi bi-x fs-5"></i>
                      Cancel
                    </button>
                  </a>
                  <a
                    class="btn btn-border me-2 mb-4 cancelbutton"
                    *ngIf="!isCreate">
                    <button
                      class="submit-btn"
                      type="button"
                      (click)="navigateBack()">
                      <i class="bi bi-x fs-5"></i>
                      Cancel
                    </button>
                  </a>
                </div>
                <div
                  class="formcontainerwrapper"
                  [hidden]="pageContent[0].hide">
                  <div class="asset-formctn">
                    <div class="formctnleft">
                      <div
                        class="input-box"
                        *ngIf="!isCreate">
                        <div class="heading-gray">Asset Group</div>
                        <div class="text-value">{{selectedAssetGroup}}</div>
                      </div>
                      <div
                        class="input-form"
                        *ngIf="isCreate">
                        <div class="heading-gray">
                          Select Asset Group *
                          <a (click)="navigateToCreateAssetGroup()" class="create-new">(Create New)</a>
                        </div>
                        <ngx-select
                          name="exceptionDetailsForm.assetGroup"
                          [(ngModel)]="exceptionDetailsForm.assetGroup"
                          [items]="allAssetGroupNames"
                          placeholder="Select Asset Group"
                          [allowClear]="true"
                          required>
                        </ngx-select>
                      </div>
                      <div
                        class="input-box"
                        *ngIf="!isCreate">
                        <div class="heading-gray">Exception Name</div>
                        <div class="text-value">{{exceptionDetailsForm.name}}</div>
                      </div>
                      <div
                        class="input-box"
                        *ngIf="isCreate">
                        <div class="heading-gray">
                          Exception Name *
                          <span
                            *ngIf="isExceptionNameValid==0"
                            class="not-available">(Not Available)</span>
                          <span
                            *ngIf="isExceptionNameValid==1"
                            class="available">(Available)</span>
                        </div>
                        <input
                          type="text"
                          name="createdBy"
                          class="form-control input-field"
                          placeholder="Enter Exception Name"
                          [(ngModel)]="exceptionDetailsForm.name"
                          (input)="isExceptionNameAvailable($any($event).target.value)"
                          autocomplete="off">
                      </div>
                    </div>
                    <div class="formctnright">
                      <div class="input-box">
                        <div class="heading-gray">Expiry Date *</div>
                        <!-- <ngx-dates-picker [options]="options" (ngModelChange)='getDateData($event)' [(ngModel)]="exceptionDetailsForm.expiry"></ngx-dates-picker>  
                         --> 
                         <input class="mydatepicker" (ngModelChange)='getDateData($event)' angular-mydatepicker name="exceptionDetailsForm.expiry"  (ngModelChange)='getDateData($event)'  [(ngModel)]="exceptionDetailsForm.expiry" [options]="myDpOptions" (click)="dp.toggleCalendar()"  #dp="angular-mydatepicker"/>
                         <!-- <ngx-dates-picker [options]="options"  minDate="date" (ngModelChange)='getDateData($event)' [(ngModel)]="exceptionDetailsForm.expiry" ></ngx-dates-picker> -->
                      </div>
                      <div class="bm10 input-box">
                        <div class="heading-gray">Exception Reason *</div>                        
                          <textarea
                            name="description"
                            class="textarea-container"
                            placeholder="Enter Exception Reason"
                            [(ngModel)]="exceptionDetailsForm.reason"></textarea>                        
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="formcontainerwrapper vertical-div-scroll"
                  *ngIf="!pageContent[1].hide">
                  <div class="attribute-contents asset-formctn">
                    <div class="outer-div">
                      <div
                        class="inner-div attrlist"
                        *ngFor="let attributeDetail of allAttributeDetails; let idx = index;"
                        [ngClass]="attributeDetail.added? 'added-target-types':''">
                        <h1>{{attributeDetail.targetName}}</h1>
                        <div class="attrspacer"></div>
                        <h3 class="added">{{attributeDetail.rules.length}}</h3>
                        <h2 class="text">Rules added</h2>
                        <div class="btnctn">
                          <button
                            class="attrbutton"
                            (click)="openAttributeConfigure(attributeDetail, idx)" data-bs-toggle="modal" data-bs-target="#modalForm">
                            CONFIG
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="attributes-not-found pacman-alert"
                    *ngIf="allAttributeDetails.length==0">
                    <div class="not-found-image"></div>
                    <div class="attributes-not-found-title">Target Types not found for :</div>
                    <div class="highlight-pink attributes-not-found-title">
                      <b>{{searchTerm}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="data-table-paginator-wrap flex flex-center flex-align-center createsubmit"  [hidden]="pageContent.hide">
                <a class="btn btn-border me-2 btn-green">
                  <button
                    class="submit-btn"
                    (click)="prevStep()"
                    [disabled]="!(stepIndex>0) || assetLoader">
                    <i class="bi-arrow-left fs-5"></i>
                    Prev
                  </button>
                </a>
                <a
                  class="btn btn-border me-2 btn-green"
                  *ngIf="stepIndex<1">
                  <button
                    class="submit-btn"
                    (click)="nextStep()"
                    [disabled]="(exceptionDetailsForm.name===''|| exceptionDetailsForm.reason===''|| exceptionDetailsForm.assetGroup[0].length===0) || assetLoader || isExceptionNameValid==0">
                    Next
                    <i class="bi-arrow-right fs-5"></i>
                  </button>
                </a>
                <a
                  class="btn btn-border me-2 btn-green"
                  *ngIf="stepIndex==1 && isCreate">
                  <button
                    class="submit-btn"
                    (click)="createException(exceptionDetailsForm)">
                    <i class="bi bi-plus fs-5"></i>
                    Create
                  </button>
                </a>
                <a
                  class="btn btn-border me-2 btn-green"
                  *ngIf="stepIndex==1 && !isCreate">
                  <button
                    class="submit-btn"
                    (click)="updateException(exceptionDetailsForm)">
                    <i class="bi bi-check fs-5"></i>
                    Update
                  </button>
                </a>
                <a class="btn btn-border me-2 cancelbutton">
                  <button
                    class="submit-btn"
                    type="button"
                    (click)="navigateBack()">
                    <i class="bi bi-x fs-5"></i>
                    Cancel
                  </button>
                </a>
              </div>
            </div>
            <div
              class="pacman-alert"
              *ngIf="isAssetGroupExceptionCreationUpdationFailed">
              <div
                class="sa-icon sa-error animateErrorIcon"
                style="display: block;">
                <span class="sa-x-mark animateXMark">
										
                  <span class="sa-line sa-left"></span>
									
                  <span class="sa-line sa-right"></span>
									</span>
              </div>
              <div class="pacman-alert-title">{{failedTitle}}</div>
              <div class="pacman-alert-message">
                Exception
                <span class="highlight-pink">{{highlightName}}</span>
                failed!!!
              </div>
              <div class="pacman-alert-button createsubmit">                
                <a
                    class="btn btn-border me-2 cancelbutton"
                    >
                    <button
                      class="submit-btn"
                      type="button"
                      (click)="closeErrorMessage()">                     
                      <span *ngIf="failedTitle!=='Loading Failed'"> <i class="bi bi-x fs-5"></i>Close</span>
                      <span *ngIf="failedTitle==='Loading Failed'"><i class="bi bi-upload fs-5"></i> Retry</span>
                    </button>
                  </a>
              </div>
            </div>
            <div
              class="pacman-alert"
              *ngIf="isAssetGroupExceptionCreationUpdationSuccess">
              <div class="sa-icon sa-success animate">
                <span class="sa-line sa-tip animateSuccessTip"></span>
                <span class="sa-line sa-long animateSuccessLong"></span>
                <div class="sa-placeholder"></div>
                <div class="sa-fix"></div>
              </div>

              <div class="pacman-alert-title">{{successTitle}}</div>
              <div class="pacman-alert-message">
                Exception
                <span class="highlight-pink">{{highlightName}}</span>
                has been successfully
                <span *ngIf="isCreate">created</span>
                <span *ngIf="!isCreate">updated</span>
                !!!
              </div>
              <div class="pacman-alert-button createsubmit">               
                <a class="btn btn-border me-2 cancelbutton">
                    <button
                      class="submit-btn"
                      type="button"
                      (click)="navigateBack()">
                      <i class="bi bi-x fs-5"></i>
                      Close
                    </button>
                  </a>
              </div>
            </div>
            <div
              class="pacman-progress"
              *ngIf="assetGroupExceptionLoader">
              <!-- <app-pacman-loader></app-pacman-loader> -->
              <div class="title">Please Wait...</div>
              <div class="pacman-alert-message">
                <span class="highlight-pink">{{highlightName}}</span>
                {{loadingContent}} is in progress...
              </div>
            </div>
            <div
              [@fadeInOut]="state"
              class="overlay"
              (click)="closeAttributeConfigure()"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section>
  <div class="modal fade configure-attributes" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog data-table-wrap relative">
      <div class="modal-content sub-head">
        <div class="modal-header bg-dark text-white">
          <h5
          class="modal-title "
          id="exampleModalLabel">Configure Rules</h5>
        <button
          type="button"
          class="btn"
          data-bs-dismiss="modal"
          (click)="closeAttributeConfigure()">
          <i class="bi bi-x-lg text-white"></i>
        </button>
        </div>        
          <div class="modal-body data-content">
            <div class="asset-formctn data-table-head-outer">
              <div class="formctn rule-attribute">
                <div class="input-box">
                  <div class="parameters rules">
                    <div class="heading-gray">Select Rule Name :</div>
                    <ngx-select
                      #targetTypeRuleSelect
                      name="attributeName"
                      [(ngModel)]="attributeName"
                      [allowClear]="true"
                      [items]="selectedAllRules"
                      placeholder="Select Rule Name"
                      required>
                    </ngx-select>                 
                  </div>
                  <button
                    class="tmbutton btm30 ib"
                    [disabled]="attributeName.length==0"
                    (click)="addAttributes(attributeName, attributeValue)"
                    >
                    + Add
                  </button>
                </div>
              </div>
              <div class="data-table-content">
                <div class="data-table-head-outer">
                  <div class="data-table-head flex flex-around flex-align-center">
                    <div class="head-cells table-cells ">
                      Rule Name
                      <span class="relative"></span>
                    </div>
                    <div class="head-cells table-cells">
                      Type
                      <span class="relative"></span>
                    </div>
                    <div class="head-cells table-cells">
                      Action
                      <span class="relative"></span>
                    </div>
                  </div>
                </div>
                <div
                  class="data-table-inner-wrap relative"
                  *ngIf="selectedIndex!==-1">
                  <div
                    currentTable
                    class="data-table-inner-content data-table-current">               
                    <div
                      class="data-table-rows flex flex-around flex-align-center"
                      *ngFor="let attribute of selectedRules; let idx = index">
                      <div class="row-cells table-cells flex flex-align-center">
                        <div class="column-wrapper">
                          <div class="nowrap-ellipsis">{{attribute?.text}}</div>
                        </div>
                      </div>
  
                      <div class="row-cells table-cells flex flex-align-center">
                        <div class="column-wrapper">
                          <div class="nowrap-ellipsis">{{attribute?.type}}</div>
                        </div>
                      </div>
                      <div class="row-cells table-cells flex flex-align-center">
                        <div class="column-wrapper">
                          <div class="nowrap-ellipsis">
                            <a (click)="deleteAttributes(attribute.name, idx)">
                              <i class="bi bi-x fs-5"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>          
        </div>
      </div>
    </div>
  </div>
</section>