<div class="all-patch-container relative">
    <div class="all-patch-content relative allpolicy">
        <app-notification-table [searchTextValues]=searchTxt [errorValue]='errorValue'
            [parentName]="'policyDetailsTableMessage'" [showGenericMessage]='showGenericMessage'
            [allColumns]='allColumns' [outerArr]='outerArr' [heightValue]="'27.4em'" [totalRows]='totalRows'
            [firstPaginator]='firstPaginator' [lastPaginator]='lastPaginator' [currentPointer]='currentPointer'
            [popRows]='popRows' (previousPageCalled)='prevPg()' (selectedRow)='goToDetails($event)'
            (nextPageCalled)='nextPg()' (searchRowTxt)='searchCalled($event)' (searchTriggerred)='callNewSearch()'
            [searchableHeader]='false' [dataHead]="'List of violations'" (rowClickText)='handlePopClick($event)'>
        </app-notification-table>
    </div>
</div>